import React from "react";
import ButtonArrow from "./ButtonArrow";

export function TopBlock() {
  return (
    <div className="md:block hidden">
      <div className="mainblock">
        <div className="mt-[9.4%] ml-[84px] lg:mr-[41%] mr-[25%] lg:text-36-700-advent text-32-700-advent uppercase grow">
          Мы делаем технологии цифрового времени доступнее
        </div>
        <div className="mx-auto h-[40px] ">
          <ButtonArrow
            style="bg-center bg-no-repeat bg-contain mx-auto bg-doubleArrow w-[14px] h-[12px]"
            onClick={() => {
              window.scrollBy({
                top: 380 - window.pageYOffset,
                behavior: "smooth",
              });
            }}
          />
        </div>
      </div>
    </div>
  );
}
