import React from "react";

interface IButtonProps {
  onClick?: () => void;
  label: string;
  style: string;
  type?: "button" | "reset" | "submit";
  disabled?: boolean;
}

const Button: React.FC<IButtonProps> = ({
  label,
  style,
  onClick,
  type,
  disabled,
}) => {
  return (
    <button
      type={type || "button"}
      onClick={(event) => {
        event.stopPropagation();
        onClick && onClick();
      }}
      className={`tracking-tighter 2xl:h-[56px] h-[40px] ${style} block rounded-xl bg-gradientBlueLight hover:bg-gradientBlueDark focus:ring-0`}
      disabled={disabled}
    >
      {label}
    </button>
  );
};

export default Button;
