import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { RoutesName } from "../components/AppRouter";
import Button from "../components/Button";
import { ButtonChevron } from "../components/ButtonChevron";
import { InputForm } from "../components/InputForm";
import { Section } from "../components/Section";
import { ModalContext } from "../context/modal/modalContext";

const landOceanProducts = [
  {
    title: "Seismic Data Acquisition and Processing Software",
    spoilers: [
      {
        id: 1,
        title: "Digital Seismic Lab software (LD-SIMO)",
        description: [
          "Digital Seismic Lab (SIMO) - программное обеспечение, применяющее численный метод для моделирования обработки сейсмических данных. ПО работает с данными в форматах 2D и 3D на процессорах CPU/GPU, имеет высокую эффективность в вычислениях.",
        ],
      },
      {
        id: 2,
        title: "Onsite Seismic Data Processing Software (LD-SeisFast)",
        description: [
          "LD-SeisFast - высокоэффективное ПО для экспресс-обработки сейсмических данных в полевых условиях. ПО включает модули для стандартного графа обработки сейсмических данных, как то: форматирование данных, определение геометрии, контроль качества полевых данных, статика преломленных и отраженных волн, деконволюция и анализ скоростей. ПО можно использовать для быстрого и эффективного анализа качества полевых данных.",
        ],
      },
      {
        id: 3,
        title: "Integrated Statics Software (LD-SAGA)",
        description: [
          "LD-SAGA - программное обеспечение, предназначенное для решения задач статических поправок в условиях сложнопостроенной ВЧР. Пользователь может выбрать один или комбинацию методов введения статических поправок для разных условий ВЧР (горы, пустыни, болота, лес и другие).",
        ],
        list: [
          "Comprehensive Model Statics",
          "TOMO Refraction Statics",
          "Residual Refraction Statics",
          "Global Optimization Residual Reflection Statics",
        ],
      },

      {
        id: 4,
        title: "CPU/GPU Co-parallel Seismic Data Processing System (LD-DPSeis)",
        description: [
          "LD-DPSeis - система обработки сейсмических данных, работающая при помощи параллельных вычислений на процессорах CPU/GPU. Система включает следующие пакеты: ",
        ],
        list: [
          "базовый пакет обработки сейсмических данных",
          "пакет введения статических поправок SAGA",
          "PSTM",
          "PSDM",
          "RTM",
          "пакет многокомпонентной обработки",
          "VSP",
        ],
      },
    ],
  },
  {
    title: "Reservoir Characterization Software",
    spoilers: [
      {
        id: 1,
        title: "Comprehensive Geologic Analysis  (GeoTalk）",
        description: ["GeoTalk - комплексное ПО для геологического анализа: "],
        list: [
          "Многоскважинная стратиграфическая корреляция для установления стратиграфического разреза",
          "Многоскважинная корреляция коллектора для установления участок резервуара",
          "Многоскважинная интерпретация осадочных фаций",
          "Автоматическое соединение слоев",
          "Редактируемый режим вырезания/перехода",
          "Выравнивание горизонта по стратиграфии/высоте",
          "Интерактивная интерпретация с сейсмическими ограничениями",
          "Стандартная библиотека геологических символов на английском и китайском языках )",
          "Выход CGM",
          "Интеграция с другими модулями ПО EPOffice",
        ],
      },
      {
        id: 2,
        title: "Modern Seismic Structural Interpretation （SeisTalk）",
        description: [
          "SeisTalk - передовое ПО для интерпретации сейсмических данных, включает: ",
        ],
        list: [
          "Стандартные геологические символы, принятые в отрасли",
          "Автоматический выбор горизонта (пикирование) и интерактивная интерпретация в трехмерном сейсмическом объеме",
          "Структурная интерпретация в глубинной области",
          "Интерпретация и картирование обратных разломов",
          "Структурное картирование с использованием скоростного моделирования Кригинга",
          "Совместное картирование и интерпретация нескольких съемок",
          "Совместная интерпретация несколькими пользователями",
          "Множество инструментов для рисования и графических элементов",
          "Статистический анализ точечных и планарных данных по скважинам",
          "Удобное отображение разрезов и модификация горизонтов/разломов",
          "Мощный и эффективный 3D-дисплей",
          "Разнообразные и гибкие инструменты интерпретации плоскости разлома",
        ],
      },
      {
        id: 3,
        title: "Reservoir Fast Imaging & Analysis （Image+）",
        description: [
          "Image+ - программное обеспечение для оперативной визуализации и анализа коллектора",
        ],
        list: [
          "Алгоритмы анализа на основе нескольких частотно-временных данных с высоким разрешением",
          "Быстрая обработка данных на основе многоядерный распараллеливание",
          "Перспективное моделирования и определение коллектора на базе скважинных данных",
          "Быстрое отображение геометрического расширения и неоднородности резервуаров",
          "Прямое обнаружение углеводородов с помощью чувствительных атрибутов анализа сейсмических данных",
        ],
      },

      {
        id: 4,
        title: "Hi-resolution Seismic Inversion & Reservoir Prediction（EPS+）",
        description: [
          "EPS+ - программное обеспечение для проведения сейсмической инверсии высокого разрешения и прогноза коллектора",
        ],
        list: [
          "Комплекс методов моделирования с подробным геологическим анализом",
          "Высокоточные методы инверсии",
          "Инверсия скорости S-волны в скважине",
          "Интерпретация автотрекинга лито-тела",
          "Прогноз резервуара с учетом расширенной информации",
        ],
      },
      {
        id: 5,
        title: "Fractured Reservoir Prediction Solution（FRS+）",
        description: [
          "FRS + - программное обеспечение для прогноза трещиноватых коллекторов",
        ],
        list: [
          "Моделирование физики горных пород анизотропного трещиноватого резервуара и его прямое сейсмическое моделирование до суммирования",
          "Анализ азимутальных сейсмических атрибутов до суммирования и извлечение параметров трещин",
          "Численное моделирование прогнозирования напряжения и трещин, связанных со структурой",
          "Высокоточный сейсмический анализ кривизны для обнаружения трещин",
          "Комплексная характеристика трещиноватых коллекторов",
        ],
      },
      //   {
      //     id: 6,
      //     title: "High-precision Fractured Reservoir Imaging （FRIM）",
      //     description: [
      //       "",
      //     ],
      //   },
      {
        id: 7,
        title:
          "Reservoir Prediction for Ocean or New Adventure Areas （OceanPro）",
        description: [
          "OceanPro - программное обеспечение для прогноза резервуаров в океане или новых районов исследования",
        ],
        list: [
          "Запись данных по псевдоскважинам",
          "Оценка сейсмических волн без информации о скважине",
          "Моделирование волнового сопротивления при сложных геологических структурах",
          "Совместное сейсмическое моделирование с использованием псевдоскважин",
          "Инверсия импеданса на основе модели",
        ],
      },
      {
        id: 8,
        title: "Pre-drilling Pressure Prediction （GPA）",
        description: [
          "GPA - программное обеспечение для прогнозирования давления перед бурением",
        ],
        list: [
          "Оценка плотности вскрышных пород на основе нескольких моделей физики горных пород",
          "Установка параметров модели прогнозирования давления и выбор модели",
          "Высокоточный прогноз давления с помощью Vp и Vs",
          "Расчет и анализ градиента давления разрыва",
          "Практические инструменты для анализа и интерпретации данных о давлении",
        ],
      },
    ],
  },
  {
    title: "Reservoir Development Software",
    spoilers: [
      {
        id: 1,
        title: "Geologic Analysis and Reservoir Modeling （LandMod）",
        description: [
          "LandMod - программное обеспечение для геологического анализа и моделирования коллектора",
        ],
        list: [
          "Поддерживает обратные и Y-образные разломы",
          "Поддержка различных стратиграфических и осадочных моделей",
          "Поддержка моделей с массивными ячейками",
          "Усовершенствованный алгоритм построения сетки для обеспечения однородности и ортогональности",
          "Поддерживает моделирование свойств, контролируемых фациями",
          "Поддерживает моделирование свойств в параллельных алгоритмах с использованием различных геостатистических методов",
          "Автоматическая подгонка параметров ",
          "Быстрый подсчет запасов пласта",
          "Обеспечивает масштабирование геометрии и свойств коллектора",
          "Поддерживает диагностику модели из динамического просмотра 3D, плоскости и сечения",
          "Поддерживает структурированные и неструктурированные грид-системы",
        ],
      },
      {
        id: 2,
        title:
          "Fractured Reservoir Modeling& Numerical Simulation Integration（LandFrac）",
        description: [
          "LandFrac - программное обеспечение для интегрированного моделирования и симуляция трещиноватых коллекторов",
        ],
        list: [
          "Интегрированное моделирование и симуляция трещин",
          "Учет разломов",
          "Коррекция и калибровка с FMI",
          "Точное дискретное моделирование трещин (DFM)",
          "Генерация высококачественной тетраэдрической сетки",
          "Моделирование неструктурированных сетей (UGS)",
        ],
      },
      {
        id: 3,
        title: "New Generation Reservoir Numerical Simulation（LandSim）",
        description: [
          "LandSim - Мультифизический симулятор резервуара с высоким разрешением",
          "Функции:",
        ],
        list: [
          "Интегрированная система сеток моделирования, включая декартовы, угловые и неструктурированные сетки",
          "Масштабирование модели на основе потока",
          "Интегрированное моделирование композиционных моделей",
          "Автоматическое сопоставление истории с использованием алгоритмов Ensemble Karman Filter (EnKF)",
          "Инструменты 2D и 3D обработки и анализа результатов моделирования",
        ],
      },
      //   {
      //     id: 4,
      //     title: "Unconventional Oil and Gas Numerical Simulation （UnconSim）",
      //     description: [
      //       "",
      //     ],
      //   },
    ],
  },
  {
    title: " Oil and Gas Development Engineering Software",
    spoilers: [
      {
        id: 1,
        title:
          "Reservoir Development Collaborative Analysis and Evaluation（ReasPro）",
        description: [
          "ReasPro - программное обеспечение для комплексного анализа и оценки разработки пласта",
          "Функции:",
        ],
        list: [
          "Подключение к центральной базе данных, направленное на передачу данных",
          "Приложение для анализа данных ГИС",
          "Динамический анализ продуктивности",
          "Расчет коэффициента запасов и извлечения",
          "Анализ индекса разработки",
          "Планирование технологии закачки и извлечения",
          "Анализ физических свойств коллектора",
          "Общая модель прогнозирования и анализа",
          "Технико-экономическое обоснование разработки",
          "Геологическое картирование",
          "Управление и настройка рабочего места геолога",
        ],
      },
      {
        id: 2,
        title: "Gas Field Development Dynamic Analysis and Diagnosis（GasPro）",
        description: [
          "GasPro - система модулей ПО для динамического анализа и диагностики разработки газовых месторождений",
          "Система состоит из 8 модулей:",
        ],
        list: [
          "Система динамического анализа добычи газовых пластов и газовых скважин",
          "Система диагностики и принятия решений по разработке газовых месторождений",
          "Инструменты проектирования геологии добычи",
          "Инструмент создания помощника для построения рамочного отчета",
          "Система разделения выхода пласта газовой скважины",
          "Общее преобразование данных и цифровое картографирование",
          "Управление конфигурацией системы",
        ],
      },
      {
        id: 3,
        title: "Undeveloped Reserves Evaluation and Decision System（UseDes）",
        description: [
          "UseDes - Система оценки и принятия решений по неразработанным запасам",
          "Функции:",
        ],
        list: [
          "Статистический анализ состояния запасов",
          "Оценка и анализ запасов",
          "Анализ экономической эффективности",
          "Комплексная оптимизация проектов разработки",
          "Оптимизация инвестиционного портфеля",
          "Вывод графиков",
          "Вывод выписки",
          "Оформление отчетов",
          "Управление документами",
        ],
      },
    ],
  },
  {
    title: "Economic Assessment and Planning Software",
    spoilers: [
      {
        id: 1,
        title:
          "Oil / Gas Field Development Project Economic Assessment and Decision（DevelopDes）",
        description: [
          "DevelopDes - Экономическая оценка проекта разработки нефтяных и газовых месторождений",
          "Функции:",
        ],
        list: [
          "Управление девелоперскими проектами",
          "Системное управление данными",
          "Управление параметрами",
          "Измерение и расчет инвестиций",
          "Разработка генерального проекта",
          "Измерение и расчет финансовых индексов",
          "Анализ безубыточности",
          "Анализ чувствительности",
          "Анализ предельной стоимости (динамический метод)",
          "Экономический вероятностный анализ",
          "Анализ предельной стоимости (статический метод)",
          "Сравнение нескольких схем",
          "Сочетание экономических показателей проектов",
          "Экономическая оценка реконструкции и проекты расширения",
          "Оптимизация проектов",
          "Оптимизация и комбинирование",
          "Графический вывод",
          "Вывод выписки",
          "Оформление отчетов",
        ],
      },
      {
        id: 2,
        title:
          "Oil / Gas Exploration Projects Evaluation and Management （ExplorDes）",
        description: [
          "ExplorDes - Оценка и управление нефтегазовыми проектами",
        ],
        list: [
          "Система предварительной оценки",
          "Система быстрой экономической оценки для проектов по разведке нефти и газа",
          "Система экономической оценки запасов и резервов нефти и газа",
          "Система оценки экономической эффективности извлекаемости запасов",
        ],
      },
    ],
  },
  //   {
  //     title: "Digital Oilfield and Management Software",
  //     spoilers: [
  //       {
  //         id: 1,
  //         title: "3D Platform （SuperEarth）",
  //         description: [
  //           "",
  //         ],
  //       },
  //       {
  //         id: 2,
  //         title: "Smart Oilfield Solution（SmartField）",
  //         description: [
  //           "",
  //         ],
  //       },
  //       {
  //         id: 3,
  //         title:
  //           "Visualized Digital Factory Solution （Digital Factory Solution）",
  //         description: [
  //           "",
  //         ],
  //       },

  //       {
  //         id: 4,
  //         title: "Pipeline Integrity Management Platform （PIMP）",
  //         description: [
  //           "",
  //         ],
  //       },
  //       {
  //         id: 5,
  //         title: "Mobile Internet Routine Inspection（MIP）",
  //         description: [
  //           "",
  //         ],
  //       },
  //       {
  //         id: 6,
  //         title: "Emergence Maintenance System （ERMS）",
  //         description: [
  //           "",
  //         ],
  //       },
  //     ],
  //   },
  //   {
  //     title: "Well Log, Geologic Log and Well Test Interpretation Software",
  //     spoilers: [
  //       {
  //         id: 1,
  //         title: "Well  Log Interpret（LogTalk）",
  //         description: [
  //           "",
  //         ],
  //       },
  //     ],
  //   },
];

export function SoftwareSolutionsProductGeophysicsLO() {
  const navigate = useNavigate();

  const { show } = useContext(ModalContext);
  return (
    <div className="relative">
      <div className="md:block hidden absolute w-[100%] aspect-[1/1] md:bg-sofwareSolutionGeoBG  bg-no-repeat bg-cover bottom-[-60px]  -z-[1]"></div>
      <div className="md:pt-[40px] pt-5 md:pb-5 md:px-0 px-5 md:flex md:space-x-5 md:space-y-0 space-y-5">
        <ButtonChevron
          label="LandOcean EPoffice"
          labelChevron="CH"
          width="md:w-[49.5%] w-full"
          onClick={() =>
            navigate(
              RoutesName.SOWTWARE_SOLUTIONS_PRODUCT_GEOPHYSICS_LAND_OCEAN
            )
          }
          active={true}
          styleChevron="bg-chevronCh"
        />
        <ButtonChevron
          label="ГМК ЦИФРА"
          labelChevron="RU"
          width="md:w-[49.5%] w-full"
          onClick={() =>
            navigate(RoutesName.SOWTWARE_SOLUTIONS_PRODUCT_GEOPHYSICS_GMK_DIGIT)
          }
          active={false}
          styleChevron="bg-chevronRu"
        />
      </div>
      <div className="flex flex-wrap justify-between">
        {landOceanProducts.map((item, index) => (
          <div
            key={index}
            className="xl:basis-[49.5%] basis-[100%] grow-0 shrink-0 mt-5 last:ml-auto last:mr-0"
          >
            <Section
              key={index}
              section={item}
              buttons={[
                <Button
                  key={index}
                  label="Оставить заявку"
                  style="w-[157px] 2xl:text-16-600-inter text-14-600-inter"
                  onClick={() =>
                    show(
                      <InputForm titleSection={`SaaS:${item.title}`} />,
                      "",
                      "md:modalInputForm modalInputFormMD"
                    )
                  }
                />,
              ]}
              bgBig="bg-gray-300"
              bgSmall="md:bgSoftwareSolutionsProductLo bgSoftwareSolutionsProductLoMD 2xl:pr-[88px] md:pr-[51px] pr-[20px]"
              isActive={true}
              isCanOpen={false}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
