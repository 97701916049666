import React from "react";
import { NavHashLink } from "react-router-hash-link";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

export interface IRoute {
  id: number;
  to: string;
  label: string;
  style: string;
}

interface IRouteWrapperProps {
  routesProduct: IRoute[];
  children: React.ReactNode;
}

export const RouteWrapper: React.FC<IRouteWrapperProps> = ({
  routesProduct,
  children,
}) => {
  const location = useLocation();
  const isTabletOrMobile = useMediaQuery({
    query: "(min-width: 768px)",
  });

  return (
    <div className="md:py-[38px] pt-[10px]">
      {location.pathname === "/integration/product" && (
        <div className="md:hidden block px-[20px] pb-[10px]">
          <div className="text-24-600-inter">Интеграционные решения</div>
        </div>
      )}
      {(location.pathname === "/software/product" ||
        location.pathname === "/software/product/geophysics") && (
        <div className="md:hidden block px-[20px] pb-[10px]">
          <div className="text-24-600-inter">Програмные решения</div>
        </div>
      )}
      <div className="bg-gray-150 rounded-medium">
        <nav
          className={`md:flex overflow-hidden rounded-medium pr-[25px] ${
            location.pathname === "/integration/product" ||
            location.pathname === "/software/product" ||
            location.pathname === "/software/product/geophysics/landocean" ||
            location.pathname === "/software/product/geophysics/gmkdigit"
              ? "flex"
              : "hidden"
          }`}
          aria-label="Breadcrumb"
        >
          <ol
            role="list"
            className="flex flex-wrap md:firstChild secondChild gap-y-[10px]"
          >
            {routesProduct.map((item, index) =>
              index === 0 || (!isTabletOrMobile && index === 1) ? (
                <li key={index} className="flex items-center relative">
                  <NavHashLink
                    smooth
                    end
                    to={item.to}
                    className={({ isActive }) =>
                      `${
                        item.style
                      } items-center h-[40px] transition-colors tracking-tighter rounded-l-medium ${
                        isActive
                          ? "bg-gray-700 md:text-16-600-inter-aquamarine text-12-600-inter-aquamarine"
                          : "bg-gray-900 md:text-16-600-inter-gray text-12-600-inter-gray"
                      }`
                    }
                  >
                    <span className="ml-5">{item.label}</span>
                    <span className="absolute h-[40px] w-[21px] inset-y-0 bg-gray-900 -right-[20px] clip"></span>
                  </NavHashLink>
                </li>
              ) : (
                <li key={index} className="relative flex items-center">
                  <NavHashLink
                    smooth
                    key={index}
                    end
                    to={item.to}
                    className={({ isActive }) =>
                      `${
                        item.style
                      } flex items-center h-[40px] tracking-tighter ${
                        isActive
                          ? "bg-gray-700 md:text-16-600-inter-aquamarine text-12-600-inter-aquamarine last-children:bg-gray-700"
                          : "bg-gray-900 md:text-16-600-inter-gray text-12-600-inter-gray last-children:bg-gray-900"
                      }`
                    }
                  >
                    <span
                      className={`absolute w-[21px] h-[40px] inset-y-0 -left-px clip z-10 bg-gray-800`}
                    >
                      <span className="absolute w-[21px] h-[40px] inset-y-0 left-[0] clip bg-gray-150"></span>
                    </span>
                    {item.label}
                    <span className="absolute w-[21px] h-[40px] inset-y-0 -right-[20px] clip"></span>
                  </NavHashLink>
                </li>
              )
            )}
          </ol>
        </nav>
      </div>
      {children}
    </div>
  );
};
