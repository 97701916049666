import React from "react";

interface IButtonChevronProps {
  onClick?: () => void;
  labelChevron: string;
  label: string;
  width: string;
  active: boolean;
  styleChevron: string;
}

export const ButtonChevron: React.FC<IButtonChevronProps> = ({
  label,
  labelChevron,
  width,
  onClick,
  active,
  styleChevron,
}) => {
  return (
    <button
      onClick={onClick}
      className={`relative block xl:pl-[90px] pl-[78px] text-left tracking-tighter h-[40px] ${width} rounded-medium bg-gray-700 hover:bg-gray-700 hover:md:text-16-600-inter-aquamarine hover:text-12-600-inter-aquamarine ${
        active
          ? "bg-gray-700 md:text-16-600-inter-aquamarine text-12-600-inter-aquamarine"
          : "bg-gray-900 md:text-16-600-inter-gray text-12-600-inter-gray"
      } `}
    >
      {label}
      <div
        className={`${styleChevron} w-[71px] h-[40px] bg-no-repeat bg-contain absolute top-0 left-0 xl:text-16-600-inter text-14-700-advent tracking-tighter md:pl-[9px] md:pt-[8px] pl-[18px] pt-[11px]`}
      >
        {labelChevron}
      </div>
    </button>
  );
};
