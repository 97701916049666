import React, { useState } from "react";
import { PartnerItem } from "../components/PartnerItem";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "./../index.css";

// Styles must use direct files

const partners = [
  {
    id: 1,
    partner: "bg-rostelekom w-[48px] h-[48px]",
    partnerMobile: "bg-rostelekom w-[80%] h-[80%]",
  },
  {
    id: 2,
    partner: "bg-academpark w-[42px] h-[49px]",
    partnerMobile: "bg-academpark w-[80%] h-[80%]",
  },
  {
    id: 3,
    partner: "bg-sugon w-[48px] h-[24px]",
    partnerMobile: "bg-sugon w-[80%] h-[80%]",
  },
  {
    id: 4,
    partner: "bg-landocean w-[48px] h-[48px]",
    partnerMobile: "bg-landocean w-[80%] h-[80%]",
  },
  {
    id: 5,
    partner: "bg-luch w-[132px] h-[72px]",
    partnerMobile: "bg-luch w-[80%] h-[80%]",
  },
  {
    id: 6,
    partner: "bg-geomash w-[99px] h-[66px]",
    partnerMobile: "bg-geomash w-[80%] h-[80%]",
  },
  {
    id: 7,
    partner: "bg-azurdrive w-[140px] h-[70px]",
    partnerMobile: "bg-azurdrive w-[80%] h-[80%]",
  },
  {
    id: 8,
    partner: "bg-gaspr w-[96px] h-[48px]",
    partnerMobile: "bg-gaspr w-[80%] h-[80%]",
  },
  {
    id: 9,
    partner: "bg-cge w-[52px] h-[49px]",
    partnerMobile: "bg-cge w-[80%] h-[80%]",
  },
  {
    id: 10,
    partner: "bg-shell w-[63px] h-[47px]",
    partnerMobile: "bg-shell w-[80%] h-[80%]",
  },
];

const projects = [
  { icon: "bg-electro", text: "Электроэнергетика" },
  { icon: "bg-education", text: "Образование" },
  { icon: "bg-proccess", text: "Процессинг" },
  { icon: "bg-money", text: "Банковский сектор" },
  { icon: "bg-heartbeat", text: "Здравоохранение" },
  { icon: "bg-cuvette", text: "Фармацевтика" },
  { icon: "bg-knifeFork", text: "Рестораны общественного питания" },
  { icon: "bg-shopping", text: "Торговые сети" },
  { icon: "bg-settings", text: "Производство" },
  { icon: "bg-transporter", text: "Логистика" },
  { icon: "bg-wallet", text: "Электронная коммерция" },
];

const projectsBig = [
  { icon: "bg-transporterBig", text: "Логистика" },
  { icon: "bg-walletBig", text: "Электронная коммерция" },
  { icon: "bg-settingsBig", text: "Производство" },
  { icon: "bg-moneyBig", text: "Банковский сектор" },
  { icon: "bg-educationBig", text: "Образование" },
  { icon: "bg-heartbeatBig", text: "Здравоохранение" },
  { icon: "bg-proccessBig", text: "Процессинг" },
  { icon: "bg-electroBig", text: "Электроэнергетика" },
  { icon: "bg-shoppingBig", text: "Торговые сети" },
  { icon: "bg-knifeForkBig", text: "Рестораны общественного питания" },
  { icon: "bg-cuvetteBig", text: "Фармацевтика" },
];

export function OurProjects() {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const [swipe, setSwipe] = useState<any>();
  return (
    <div>
      <div className="md:hidden block px-[20px]">
        <div className="text-20-600-inter">Наши проекты</div>
      </div>
      <div className="bg-gray-400 rounded-3xl">
        <div className="text-20-600-inter py-[20px] 2xl:px-[90px] md:px-[51px] px-[20px] p-5 md:mt-[40px] mt-[20px]">
          Решение для вашего бизнеса
        </div>
        <div className="bg-gray-700 md:bgOurpPojects md:rounded-3xl rounded-t-3xl pt-[28px] md:pb-[47px] pb-[30px] px-[20px] bg-bg-x-43px">
          <div className=" md:text-16-500-inter text-14-500-inter-hight tracking-[0.1px] 2xl:px-[67px] md:px-[40px]">
            Мы ежедневно внедряем лучшие отраслевые практики для цифровой
            трансформации вашего бизнеса.
          </div>
          <div className="md:text-16-500-inter text-14-500-inter-hight tracking-[0.1px] 2xl:px-[67px] md:px-[40px] mt-[9px]">
            Доверяя нам управление облачной инфраструктурой, вы позволяете своим
            командам сосредоточиться на достижении целей развития.
          </div>

          <div className="md:flex hidden flex-wrap justify-between md:pl-[50px] xl:pr-[137px] md:pr-[50px] md:px-[20px] pb-[15px]">
            <div className="xl:basis-[63%] basis-[100%] block xl:px-[20px] pt-[44px]">
              <div className="md:text-20-500-inter text-16-700-inter ">
                Проекты отрасли
              </div>
              <div className="columns-2  mt-[10px]">
                {projectsBig.map((item, index) => (
                  <div
                    key={index}
                    className="flex items-center pt-[10px] break-inside-avoid-column md:space-x-[48px] space-x-5"
                  >
                    <div
                      className={`${item.icon} bg-no-repeat bg-contain grow-0 shrink-0 h-[48px] w-[40px]`}
                    ></div>
                    <div className="md:text-16-500-inter text-12-500-inter">
                      {item.text}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="md:block hidden xl:basis-[auto] basis-[100%] borderAquamarine mt-[43px]">
              <div className="text-20-500-inter px-[20px] pt-[20px]">
                Нам доверяют
              </div>
              <div className="xl:grid xl:grid-cols-2 gap-[22px] flex flex-wrap justify-around px-[20px] pb-[20px] mt-[20px]">
                {partners.map((item) => (
                  <div
                    key={item.id}
                    className="w-[157px] h-[58px] bg-white rounded-medium flex justify-center items-center overflow-hidden"
                  >
                    <div
                      className={`${item.partner} bg-no-repeat bg-contain bg-center`}
                    ></div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="md:hidden block px-[20px] pt-[20px] bg-gray-700">
          <div className="text-16-700-advent">Проекты отрасли</div>
          <div className="columns-2 gap-x-[20px] mt-[10px]">
            {projects.map((item, index) => (
              <div
                key={index}
                className="flex items-center py-[10px] break-inside-avoid-column space-x-5"
              >
                <div
                  className={`${item.icon} bg-no-repeat bg-contain grow-0 shrink-0 h-[24px] w-[24px]`}
                ></div>
                <div className="text-12-500-inter">{item.text}</div>
              </div>
            ))}
          </div>
        </div>
        <div className="md:hidden block md:bg-transparent bg-gray-700 pt-[44px] 2xl:px-[94px] md:px-[51px] px-[20px] pb-[35px] md:text-24-700-advent text-16-700-advent tracking-[0.6px]">
          Нам доверяют
        </div>
        <div className="md:hidden block bg-gray-700 pb-10 px-[30px]">
          <div className="relative">
            <div
              className="w-[11px] h-[11px] bg-prev bg-contain bg-no-repeat cursor-pointer absolute top-[50%] left-[-20px] translate-y-[-50%]"
              onClick={() => swipe?.slidePrev()}
            ></div>
            <div
              className="w-[11px] h-[11px] bg-next bg-contain bg-no-repeat cursor-pointer absolute top-[50%] right-[-20px] translate-y-[-50%]"
              onClick={() => swipe?.slideNext()}
            ></div>
            <Swiper
              onBeforeInit={(swipper) => setSwipe(swipper)}
              loop={true}
              spaceBetween={20}
              slidesPerView={3}
              className="mySwiper"
            >
              {partners.map((item) => (
                <SwiperSlide key={item.id}>
                  <PartnerItem partner={item.partnerMobile} />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className="mt-[30px] text-center text-16-700-inter">
            Платите только за то, что используете
          </div>
        </div>
      </div>
    </div>
  );
}
