import React from "react";
import { RoutesName } from "./AppRouter";
import { ButtonLink } from "./ButtonLink";

interface ISoftwareSolutionsProductItemProps {
  image: string;
  title: string;
  description: string;
}

export function SoftwareSolutionsProductItem({
  title,
  description,
  image,
}: ISoftwareSolutionsProductItemProps) {
  return (
    <div
      className={`xl:basis-[37%] md:basis-[40%] basis-[100%] sm:grow-0 grow shrink bg-gray-400 rounded-3xl sm:mt-[38px] mt-[20px] mr-[20px] md:ml-0 ml-[20px]`}
    >
      <div className="flex flex-col h-full">
        <div className="md:py-[30px] py-[20px] px-[54px] ">
          <div className="sm:text-20-700-advent text-16-700-advent">
            {title}
          </div>
        </div>
        <div className="flex flex-col grow bg-gray-700 rounded-3xl pt-[25px] px-[21px] lg:pb-[68px] pb-[40px]">
          <div className="grow 2xl:text-16-500-inter text-14-500-inter lg:pr-[82px] pl-[9px]">
            {description}
          </div>
          <div className="flex lg:flex-row flex-col-reverse items-end justify-between xl:pr-[53px] lg:mx-0 mx-auto">
            <ButtonLink
              to={`${RoutesName.SOWTWARE_SOLUTIONS_PRODUCT_GEOPHYSICS_LAND_OCEAN}#top`}
              width="w-[157px]"
              label="Продукция"
              text="h-[56px] 2xl:text-16-600-inter text-14-600-inter tracking-tighter"
            />
            <div
              className={`h-[157px] w-[157px] ${
                image || "bg-emptyCard bg-no-repeat bg-63%-100%"
              } bg-center bg-no-repeat bg-contain md:mb-0 mb-[20px]`}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
}
