import React from "react";
import { HardwareSolutions } from "./HardwareSolutions";
import { IntegrationSolutions } from "./IntegrationSolutions";
import { SoftwareSolutions } from "./SoftwareSolutions";
import { NavbarMobile } from "../components/NavbarMobile";

function Home() {
  return (
    <div className="md:mt-[40px] mt-[0]">
      <div className=" md:block hidden">
        <div>
          <div className="mt-6">
            <div className="md:borderAquamarine border-none px-5 pb-5">
              <div className="text-24-700-inter pt-[20px] pl-[68px] tracking-[0.8px]">
                Аппаратные решения
              </div>
              <HardwareSolutions />
            </div>
          </div>
          <div className="mt-6">
            <div className="md:borderAquamarine border-none px-5 pb-5">
              <div className="text-24-700-inter pt-[20px] pl-[68px] tracking-[0.8px]">
                Интеграционные решения
              </div>
              <IntegrationSolutions />
            </div>
          </div>
          <div className="mt-6">
            <div className="md:borderAquamarine border-none px-5 pb-5">
              <div className="text-24-700-inter pt-[20px] pl-[68px] tracking-[0.8px]">
                Программные решения
              </div>
              <SoftwareSolutions />
            </div>
          </div>
        </div>
      </div>
      <div className="md:hidden block">
        <div className="flex flex-col h-[100vh] pb-[5px]">
          <div className="grow topHomeMobile rounded-medium relative">
            <div className="topHomeTriangle absolute h-[171px] w-full bottom-0 left-0 "></div>
          </div>

          <div className="grow-0 text-18-700-inter uppercase tracking-widest px-[48px] pt-[2px] pb-[8px]">
            Мы делаем технологии цифрового времени доступнее
          </div>
          <NavbarMobile />
        </div>
      </div>
    </div>
  );
}

export default Home;
