import React, { useContext } from "react";
import { useForm, Controller, FieldValues } from "react-hook-form";
import InputMask from "react-input-mask";
import { ModalContext } from "../context/modal/modalContext";
import ApplicationAccepted from "./ApplicationAccepted";
import Button from "./Button";
import Input from "./Input";
import Textarea from "./Textarea";
import { sendData } from "../service/service";
import SomethingWrong from "./SomethingWrong";

interface IInputFormProps {
  titleSection?: string;
}

export const InputForm: React.FC<IInputFormProps> = ({ titleSection }) => {
  const { show } = useContext(ModalContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();

  const onSubmit = async (data: FieldValues) => {
    try {
      await sendData({ ...data, landing_section_name: titleSection });
      show(<ApplicationAccepted />, "", "modalBase mt-10");
    } catch (error) {
      show(<SomethingWrong />, "", "modalBase mt-10");
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="pt-[3px] pb-[17px] md:pl-[38px] pl-5 pr-[16px]">
        <div className="mb-18px">
          <Input
            register={register}
            label="Наименование компании"
            type="text"
            id="company_name"
          />
        </div>
        <div className="mb-18px">
          <Input
            register={register}
            registerOptions={{
              required: false,
              validate: {
                length: (value) =>
                  value.length === 0 ||
                  value.length === 10 ||
                  value.length === 12,
              },

              pattern: /^[0-9]+$/,
            }}
            error={errors.taxpayer_id}
            errorMessage="В поле ИНН должно быть введено либо 10, либо 12 цифр"
            label="ИНН"
            type="text"
            id="taxpayer_id"
          />
        </div>
        <div className="mb-18px">
          <Input
            register={register}
            registerOptions={{ required: true }}
            error={errors.user_full_name}
            errorMessage="Заполните пожалуйста обязательное поле"
            label="Фамилия Имя Отчество*"
            type="text"
            id="user_full_name"
          />
        </div>
        <div className="mb-18px">
          <Input
            register={register}
            label="Имя пользователя (Телеграмм)"
            type="text"
            id="telegramm_username"
          />
        </div>
        <div className="mb-18px">
          <Input
            register={register}
            registerOptions={{
              required: "Заполните пожалуйста обязательное поле",
              pattern: {
                value:
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: "Введите пожалуйта верный формат",
              },
            }}
            errorMessage={errors.user_email?.message}
            error={errors.user_email}
            label="E-mail*"
            type="text"
            id="user_email"
          />
        </div>
        <div className="mb-18px">
          <label
            className="block text-16-500-inter text-left"
            htmlFor="user_phone_number"
          >
            Телефон*
          </label>
          <InputMask
            {...register("user_phone_number", { required: true })}
            className={`w-full px-8 pt-3 pb-2 mt-1 text-16-500-inter tracking-[1px] rounded-xl bg-transparent border-gray-500 border-solid border-2 outline-none  caret-white   focus:ring-0 ${
              errors.phone
                ? "border-pearch active:border-pearch hover:border-pearch focus:border-pearch"
                : "border-gray-500 active:border-white hover:border-gray-600 focus:border-white"
            }`}
            mask="+9 (999) 999-99-99"
            maskChar=""
          />
          {errors.user_phone_number && (
            <p className="mt-1 text-16-500-inter-pearch">
              Заполните пожалуйста обязательное поле
            </p>
          )}
        </div>

        <div className="mb-18px">
          <Textarea register={register} label="Текст сообщения" id="message" />
        </div>
        <div className="mb-18px">
          <div className="flex items-center">
            <Controller
              control={control}
              name="agreement"
              rules={{
                required: "Заполните согласие на обработку персональных данных",
              }}
              defaultValue={false}
              render={({ field: { value, ref, ...field } }) => (
                <input
                  {...field}
                  {...ref}
                  checked={!!value}
                  id="checkbox"
                  type="checkbox"
                  name="checkbox"
                  className="form-checkbox w-[15px] h-4 outline-none rounded-medium border-solid border-whiteLight border bg-transparent checked:bg-checkbox text-gradient focus:ring-0 focus:ring-offset-0 hover:cursor-pointer"
                />
              )}
            />

            <label
              htmlFor="checkbox"
              className="ml-3 text-14-500-inter border-none"
            >
              Согласие на обработку персональных данных
            </label>
          </div>
          {errors.agreement?.message && (
            <p className="mt-1 text-16-500-inter-pearch">
              {errors.agreement?.message}
            </p>
          )}
        </div>
        <Button
          style="md:w-392 w-[170px] block 2xl:text-16-600-inter text-14-600-inter md:mx-0 mx-auto"
          label="Оставить заявку "
          type="submit"
        />
      </div>
    </form>
  );
};
