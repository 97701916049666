import React from "react";
import { Routes, Route } from "react-router-dom";
import { HardwareSolutions } from "../pages/HardwareSolutions";
import Home from "../pages/Home";
import { IntegrationSolutions } from "./../pages/IntegrationSolutions";
import { SoftwareSolutions } from "../pages/SoftwareSolutions";
import { IntegrationSolutionsProduct } from "../pages/IntegrationSolutionsProduct";
import { SoftwareSolutionsProduct } from "../pages/SoftwareSolutionsProduct";
import { RouteWrapper } from "./RouteWrapper";
import { OurProjects } from "../pages/OurProjects";
import { SoftwareSolutionsProductGeophysicsLO } from "../pages/SoftwareSolutionsProductGeophysicsLO";
import { SoftwareSolutionsProductGeophysicsGMK } from "./../pages/SoftwareSolutionsProductGeophysicsGMK";

export enum RoutesName {
  HARDWARE_SOLUTIONS = "/hardware",
  INTEGRATION_SOLUTIONS = "/integration",
  INTEGRATION_SOLUTIONS_PRODUCT = "/integration/product",
  SOWTWARE_SOLUTIONS = "/software",
  SOWTWARE_SOLUTIONS_PRODUCT = "/software/product",
  SOWTWARE_SOLUTIONS_PRODUCT_GEOPHYSICS_LAND_OCEAN = "/software/product/geophysics/landocean",
  SOWTWARE_SOLUTIONS_PRODUCT_GEOPHYSICS_GMK_DIGIT = "/software/product/geophysics/gmkdigit",
  OUR_PROJECTS = "/projects",
  HOME = "/",
}

const mainStyle = "md:flex hidden px-[28px]";
const style = "flex md:pl-[100px] md:pr-[68px] pl-[40px] pr-[15px]";
const styleFirstMd = "flex md:pl-[100px] md:pr-[68px] pr-[15px]";

const hardwarwSolutionsRoutes = [
  { id: 1, to: "/#top", label: "Главная", style: mainStyle },
  {
    id: 2,
    to: "/hardware#top",
    label: "Аппаратные решения",
    style,
  },
];

const integrationSolutionsRoutes = [
  { id: 1, to: "/#top", label: "Главная", style: mainStyle },
  {
    id: 2,
    to: "/integration#top",
    label: "Интеграционные решения",
    style: styleFirstMd,
  },
];

const softwareSolutionsRoutes = [
  { id: 1, to: "/#top", label: "Главная", style: mainStyle },
  {
    id: 2,
    to: "/software#top",
    label: "Програмные решения",
    style,
  },
];

const ourProjectsRoutes = [
  { id: 1, to: "/#top", label: "Главная", style: mainStyle },
  {
    id: 2,
    to: "/projects#top",
    label: "Наши проекты",
    style,
  },
];

const integrationSolutionsProductRoutes = [
  { id: 1, to: "/#top", label: "Главная", style: mainStyle },
  {
    id: 2,
    to: "/integration#top",
    label: "Интеграционные решения",
    style: styleFirstMd,
  },
  {
    id: 3,
    to: "/integration#top",
    label: "Системная интеграция",
    style,
  },
  {
    id: 4,
    to: "/integration/product#top",
    label: "Продукция",
    style,
  },
];

const softwareSolutionsProductRoutes = [
  { id: 1, to: "/#top", label: "Главная", style: mainStyle },
  {
    id: 2,
    to: "/software#top",
    label: "Програмные решения",
    style: styleFirstMd,
  },
  {
    id: 3,
    to: "/software#top",
    label: "Аренда ПО",
    style,
  },
  {
    id: 4,
    to: "/software/product#top",
    label: "Продукция",
    style,
  },
];

const softwareSolutionsProductRoutesGeophysics = [
  { id: 1, to: "/#top", label: "Главная", style: mainStyle },
  {
    id: 2,
    to: "/software#top",
    label: "Програмные решения",
    style: styleFirstMd,
  },

  {
    id: 3,
    to: "/software#top",
    label: "Аренда ПО",
    style,
  },
  {
    id: 4,
    to: "/software/product#top",
    label: "Продукция",
    style,
  },
  {
    id: 5,
    to: "/software/product/geophysics#top",
    label: "Геофизика",
    style,
  },
];

const AppRouter: React.FC = () => {
  return (
    <Routes>
      <Route
        path={RoutesName.HARDWARE_SOLUTIONS}
        element={
          <RouteWrapper routesProduct={hardwarwSolutionsRoutes}>
            <HardwareSolutions />
          </RouteWrapper>
        }
      />

      <Route
        path={RoutesName.INTEGRATION_SOLUTIONS_PRODUCT}
        element={
          <RouteWrapper routesProduct={integrationSolutionsProductRoutes}>
            <IntegrationSolutionsProduct />
          </RouteWrapper>
        }
      />
      <Route
        path={RoutesName.INTEGRATION_SOLUTIONS}
        element={
          <RouteWrapper routesProduct={integrationSolutionsRoutes}>
            <IntegrationSolutions />
          </RouteWrapper>
        }
      />
      <Route
        path={RoutesName.SOWTWARE_SOLUTIONS_PRODUCT_GEOPHYSICS_LAND_OCEAN}
        element={
          <RouteWrapper
            routesProduct={softwareSolutionsProductRoutesGeophysics}
          >
            <SoftwareSolutionsProductGeophysicsLO />
          </RouteWrapper>
        }
      />

      <Route
        path={RoutesName.SOWTWARE_SOLUTIONS_PRODUCT_GEOPHYSICS_GMK_DIGIT}
        element={
          <RouteWrapper
            routesProduct={softwareSolutionsProductRoutesGeophysics}
          >
            <SoftwareSolutionsProductGeophysicsGMK />
          </RouteWrapper>
        }
      />

      <Route
        path={RoutesName.SOWTWARE_SOLUTIONS_PRODUCT}
        element={
          <RouteWrapper routesProduct={softwareSolutionsProductRoutes}>
            <SoftwareSolutionsProduct />
          </RouteWrapper>
        }
      />

      <Route
        path={RoutesName.SOWTWARE_SOLUTIONS}
        element={
          <RouteWrapper routesProduct={softwareSolutionsRoutes}>
            <SoftwareSolutions />
          </RouteWrapper>
        }
      />

      <Route
        path={RoutesName.OUR_PROJECTS}
        element={
          <RouteWrapper routesProduct={ourProjectsRoutes}>
            <OurProjects />
          </RouteWrapper>
        }
      />
      <Route path={RoutesName.HOME} element={<Home />} />
    </Routes>
  );
};

export default AppRouter;
