import React from "react";

interface IButtonProps {
  onClick?: () => void;
  label: string;
  isActive: boolean;
}

const ButtonDark: React.FC<IButtonProps> = ({
  label,
  onClick,

  isActive,
}) => {
  return (
    <button
      type="button"
      onClick={(event) => {
        event.stopPropagation();
        onClick && onClick();
      }}
      className={`tracking-tighter text-14-700-advent px-[28px] h-[46px] w-full text-left block border border-solid border-gray-700 rounded-medium focus:ring-0 ${
        isActive ? "bg-gradientBlueDark" : "bg-transparent"
      }`}
    >
      {label}
    </button>
  );
};

export default ButtonDark;
