import { IPrice } from "../components/Calculator";

export const getCurrentSummary = (
  price: IPrice,
  currentSelectedLocations: string,
  protsessor: number,
  ram: number,
  ip: number,
  systemDiskSpace: number,
  currentTypesSystemDisk: string,
  productivity: number
) => {
  const currentSummary: number =
    protsessor * price[currentSelectedLocations].protsessor.price +
    ram * price[currentSelectedLocations].ram.price +
    ip * price[currentSelectedLocations].ip.price +
    systemDiskSpace *
      (currentTypesSystemDisk === "SAS" ? 1 : 0) *
      price[currentSelectedLocations].systemDiskSpace.SAS.price +
    Math.ceil(systemDiskSpace / 10) *
      (currentTypesSystemDisk === "SATA" ? 1 : 0) *
      price[currentSelectedLocations].systemDiskSpace.SATA.price +
    systemDiskSpace *
      (currentTypesSystemDisk === "SSD" ? 1 : 0) *
      price[currentSelectedLocations].systemDiskSpace.SSD.price +
    ((currentTypesSystemDisk === "SSD" ? 1 : 0) *
      systemDiskSpace *
      productivity *
      price[currentSelectedLocations].productivityPrice) /
      10;

  return Math.round(currentSummary * 100) / 100;
};
