import React, { useContext } from "react";
import { ModalContext } from "../context/modal/modalContext";
import Button from "./Button";

function SomethingWrong() {
  const { hide } = useContext(ModalContext);
  return (
    <div>
      <h5 className="my-[26px] text-32-700-advent text-center">
        Что-то пошло не так
      </h5>

      <Button
        style="w-312 2xl:text-16-600-inter text-14-600-inter"
        onClick={hide}
        label="OK"
      />
    </div>
  );
}

export default SomethingWrong;
