import React from "react";
import { useLocation } from "react-router-dom";

export function Footer() {
  const location = useLocation();

  return (
    <div
      className={`${
        location.pathname === "/" ? "md:block hidden mt-10" : "mt-5"
      } relative`}
    >
      {location.pathname === "/software/product" && (
        <div className="md:block hidden absolute w-full aspect-2/5 bg-softwareProductBG  bg-no-repeat bg-cover md:top-[-243%]  h-[40vw] -z-[1]"></div>
      )}

      {/* {location.pathname === "/projects" ? <BanerEarth /> : <BannerCommon />} */}
      <div className="py-[20px] px-[90px] bg-banner bg-no-repeat bg-cover bg-right-top rounded-3xl md:block hidden">
        <div className="text-24-700-inter mt-5">
          {location.pathname === "/projects"
            ? "Платите только за то, что используете"
            : "Решения для вашего бизнеса"}
        </div>
        {/* <div className="2xl:text-20-600-inter text-18-600-inter mt-[2.2%] tracking-[0.15px]">
        Мы ежедневно внедряем лучшие отраслевые практики для цифровой
        трансформации вашего бизнеса.
      </div>
      <div className="2xl:text-20-600-inter text-18-600-inter mt-[2.2%] tracking-[0.15px]">
        Доверяя нам управление облачной инфраструктурой, вы позволяете своим
        командам сосредоточиться на достижении целей развития.
      </div> */}
        <div className="uppercase md:text-20-500-inter text-14-500-inter mt-[30px]">
          ГМК ЦИФРА
        </div>
        <div className="text-14-500-inter mt-[6px]">г. Новосибирск</div>
        <div className="text-14-500-inter mt-[20px]">
          e-mail: info@gmk-digit.ru
        </div>
      </div>

      <div className="md:bg-gray-450 md:rounded-t-3xl md:bgFooter bgFooterMD md:hidden flex md:flex-row  flex-col justify-between md:items-center items-start md:mt-[44px] xl:pl-[13.7%] pl-[48px] xl:pr-[10.2%] pr-[4%] md:pt-[24px] pt-[16px] md:pb-[35px] pb-[16px]">
        <div className="lg:text-24-500-inter uppercase md:text-20-500-inter text-14-500-inter tracking-[0.3px] md:mt-[11px]">
          ГМК ЦИФРА
        </div>
        <div className="lg:text-18-500-inter md:text-16-600-inter text-14-500-inter tracking-[0.5px md:ml-[167px] ml-[0] mt-[11px]">
          г. Новосибирск
        </div>
        <div className="lg:text-18-500-inter md:text-16-600-inter text-14-500-inter tracking-[0.25px] mt-[11px]">
          e-mail: info@gmk-digit.ru
        </div>
      </div>
    </div>
  );
}
