import React, { useContext, useEffect } from "react";
import { Transition } from "@headlessui/react";
import { useMediaQuery } from "react-responsive";

import {
  IModalContextInterface,
  ModalContext,
} from "../context/modal/modalContext";

export function Modal() {
  const { modal, hide } = useContext<IModalContextInterface>(ModalContext);
  const isHeight = useMediaQuery({
    query: "(min-height: 1080px)",
  });

  useEffect(() => {
    if (modal.active) {
      document.body.classList.add("lock");
    } else {
      document.body.classList.remove("lock");
    }
  }, [modal.active]);

  return (
    <div
      className={
        modal.active
          ? `h-100vh w-full overflow-x-hidden bg-gray-100 fixed top-0 left-0 flex ${
              !isHeight ? "items-start" : "items-center"
            } justify-center opacity-100 pointer-events-auto z-50`
          : `h-100vh w-full overflow-x-hidden bg-gray-100 fixed top-0 left-0 flex ${
              !isHeight ? "items-start" : "items-center"
            } justify-center opacity-0 pointer-events-none`
      }
      onClick={hide}
    >
      <Transition
        show={modal.active}
        enter="transition-opacity duration-200"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div
          className={
            modal.active
              ? `h-fit ${modal.wrapperStyle}`
              : `h-fit ${modal.wrapperStyle} max-h-full`
          }
          onClick={(e) => e.stopPropagation()}
        >
          <div className="flex justify-between items-center">
            <div className="text-24-700-advent tracking-[0.8px] ml-5">
              {modal.title}
            </div>
            <div
              onClick={hide}
              className="bg-close bg-center h-4 bg-no-repeat w-4 ml-auto cursor-pointer"
            ></div>
          </div>

          {modal.node}
        </div>
      </Transition>
    </div>
  );
}

export default Modal;
