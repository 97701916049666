import React from "react";
import { NavLink } from "react-router-dom";

interface IButtonLinkProps {
  to: string;
  width: string;
  label: string;
  text: string;
  onClick?: () => void;
}

export const ButtonLinkMobile: React.FC<IButtonLinkProps> = ({
  to,
  width,
  label,
  text,
  onClick,
}) => {
  return (
    <NavLink
      to={to}
      className={() =>
        `flex justify-center items-center text-center ${text} h-[50px] border border-solid border-gray-700  rounded-medium   ${width} bg-transparent`
      }
      onClick={onClick}
    >
      {label}
    </NavLink>
  );
};
