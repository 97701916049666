import React from "react";
import { UseFormRegister, FieldValues, RegisterOptions } from "react-hook-form";

interface IInputProps {
  label: string;
  id: string;
  type: string;
  register: UseFormRegister<FieldValues>;
  registerOptions?: RegisterOptions;
  error?: string;
  errorMessage?: string;
}

const Input: React.FC<IInputProps> = ({
  label,
  id,
  type,
  register,
  registerOptions,
  error,
  errorMessage,
}) => {
  return (
    <div className="relative">
      <label className="block text-16-500-inter text-left" htmlFor={label}>
        {label}
      </label>

      <input
        {...register(id, registerOptions)}
        className={`w-full px-8 pt-3 pb-2 mt-1 text-16-500-inter rounded-xl bg-transparent border-gray-500 border-solid border-2 outline-none  caret-white   focus:ring-0 ${
          error
            ? "border-pearch active:border-pearch hover:border-pearch focus:border-pearch"
            : "border-gray-500 active:border-white hover:border-gray-600 focus:border-white"
        }`}
        id={id}
        type={type}
      />
      {error && <p className="mt-1 text-16-500-inter-pearch">{errorMessage}</p>}
    </div>
  );
};

export default Input;
