import React from "react";

export type ISpoilerItem = {
  id: number;
  icon?: string;
  title: string;
  subtitle?: string;
  description?: string[];
  list?: string[];
};

interface ISpoilerProductionProps {
  spoiler: ISpoilerItem;
  isBig?: boolean;
}

const SpoilerProductions: React.FC<ISpoilerProductionProps> = ({
  spoiler,
  isBig,
}) => {
  return (
    <div className="mt-18px">
      <details className="group">
        <summary
          className={`${
            isBig
              ? "pt-[30px] pr-[30px] pb-[27px] pl-[23px] group-open:pb-[13px]"
              : "pl-6 pr-[27px] pt-4 pb-2 group-open:pb-0"
          }  flex items-center justify-between cursor-pointer bg-gray-800 rounded group-open:rounded-b-none group-open:bg-gray-450 ${
            !spoiler.description && "pointer-events-none"
          }`}
        >
          <div className="flex items-center space-x-4">
            {spoiler.icon && (
              <div
                className={`${spoiler.icon} h-[46px] w-[46px] bg-center bg-no-repeat shrink-0 md:block hidden`}
              ></div>
            )}
            <div>
              <div
                className={`${
                  isBig
                    ? "2xl:text-24-600-inter md:text-20-600-inter text-14-600-inter tracking-[-1.2px] leading-8"
                    : "text-16-600-inter"
                } pr-5`}
              >
                {spoiler.title}
              </div>
              <div
                className={`${
                  isBig
                    ? "2xl:text-20-600-inter md:text-16-600-inter text-12-600-inter tracking-[-1px]"
                    : "text-14-600-inter"
                } md:mt-[0] mt-[10px] pr-5`}
              >
                {spoiler.subtitle}
              </div>
            </div>
          </div>

          <div
            className={`${
              isBig ? "bg-arrowBig" : "bg-arrow"
            }  bg-center mb-[10px] h-4 w-4 bg-no-repeat transition duration-300 group-open:rotate-180 shrink-0 ${
              !spoiler.description && "hidden"
            }`}
          ></div>
        </summary>
        <div
          className={`${
            isBig
              ? "2xl:text-16-500-inter-light md:text-14-600-inter-light text-12-500-interLight tracking-[-0.9px] md:pr-[80px] pr-[40px] md:pl-[86px] pl-[40px] pb-[24px]"
              : "md:text-14-500-inter-light  text-12-500-interLight px-6 pt-4 pb-[15px]"
          }   bg-gray-450 rounded group-open:pt-0 group-open:rounded-t-none`}
        >
          {spoiler.description &&
            spoiler.description.map((item, index) => (
              <p
                key={index}
                className={`${
                  isBig
                    ? "first:pt-[0]"
                    : "mt-[10px] first:mt-0 first:pt-[10px]"
                }`}
              >
                {item}
              </p>
            ))}
          <ul
            className={`${
              isBig ? "list-disc mt-[4px] ml-[10px]" : "list-none mt-6 md:ml-4"
            }`}
          >
            {spoiler.list &&
              spoiler.list.map((item, index) => (
                <li
                  key={index}
                  className={`${
                    isBig ? "" : "hexagonLi bg-li md:bg-li-md pl-[10px]"
                  } md:ml-4`}
                >
                  {item}
                </li>
              ))}
          </ul>
        </div>
      </details>
    </div>
  );
};

export default SpoilerProductions;
