import { createContext } from "react";

export interface IModal {
  active?: boolean;
  title?: string;
  node?: React.ReactNode;
  wrapperStyle?: string;
}

export interface IModalContextInterface {
  show: (node: React.ReactNode, title: string, wrapperStyle: string) => void;
  hide: () => void;
  modal: IModal;
}

export const ModalContext = createContext<IModalContextInterface>(
  {} as IModalContextInterface
);
