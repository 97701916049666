import { IModal } from "./modalContext";

export enum ActionsModal {
  SHOW_MODAL = "SHOW_MODAL",
  HIDE_MODAL = "HIDE_MODAL",
}

interface IHandlers {
  [key: string]: (state: IModal, action: TAction) => IModal;
}

const handlers: IHandlers = {
  [ActionsModal.SHOW_MODAL]: (state: IModal, action: TAction) => ({
    ...action.payload,
    active: true,
  }),
  [ActionsModal.HIDE_MODAL]: (state: IModal) => ({
    ...state,
    active: false,
  }),
  DEFAULT: (state: IModal) => state,
};

type TTypeAction =
  | ActionsModal.SHOW_MODAL
  | ActionsModal.HIDE_MODAL
  | "DEFAULT";

type TAction = {
  type: TTypeAction;
  payload?: IModal;
};

export const modalReducer = (state: IModal, action: TAction) => {
  const handle = handlers[action.type] || handlers.DEFAULT;
  return handle(state, action);
};
