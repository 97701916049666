import React, { useContext, useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { InputForm } from "../components/InputForm";
import { Section } from "../components/Section";
import { ModalContext } from "../context/modal/modalContext";
import Button from "./../components/Button";
import { SolutionsWrapper } from "../components/SolutionsWrapper";
import { ButtonLink } from "../components/ButtonLink";
import { RoutesName } from "./../components/AppRouter";
import ButtonDark from "../components/ButtonDark";
import ButtonArrow from "../components/ButtonArrow";

const sectionSoftwareDevelopment = {
  title: "Разработка программного обеспечения",
  description: [
    "Мы предлагаем сотрудничество в области создания программного обеспечения для распределенных вычислительных систем, специализированных программно-аппаратных комплексов и оборудования, персональных компьютеров, мобильных устройств и Web платформ по проектной модели или усиленем вашей команды.",
    "Услуги в области разработки программного обеспечения:",
  ],
  list: [
    "Проектирование",
    "Прототипирование",
    "Реализация",
    "Интеграция приложений",
    "Независимое тестирование",
    "Сопровождение и поддержка",
  ],
  note: "",
  spoilers: [],
};

const sectionSoftwareRental = {
  title: "Аренда программного обеспечения (SaaS)",
  description: [
    "Получение программного обеспечения осуществляется по модели «ПО как услуга» (Software as a Service, SaaS), вы получаете в пользование уже установленные и полностью настроенные приложения. Использование этого сервиса дает возможность предприятиям и организациям избежать высоких единовременных затрат на закупку большого количества лицензий и обеспечивает высокую скорость развертывания программных решений в эксплуатацию.",
  ],
  list: [
    "Широкий выбор облачных приложений",
    "Минимальные сроки внедрения",
    "Гарантированно высокий уровень надежности и безопасности",
    "Отсутствие операционных затрат на неиспользуемое ПО",
    "Защищенный доступ с различных устройств из любой точки мира",
    "Удобный режим самообслуживания",
    "Круглосуточная техническая поддержка",
  ],
  note: "",
  spoilers: [],
};

export function SoftwareSolutions() {
  const { show } = useContext(ModalContext);
  const [firstButton, setFirstButton] = useState<boolean>(true);
  const [secondButton, setSecondButton] = useState<boolean>(false);
  const isTabletOrMobile = useMediaQuery({
    query: "(min-width: 768px)",
  });

  useEffect(() => {
    if (isTabletOrMobile) {
      setFirstButton(true);
      setSecondButton(true);
    } else {
      setFirstButton(true);
      setSecondButton(false);
    }
  }, [isTabletOrMobile]);

  const buttonHandler = () => {
    setFirstButton(!firstButton);
    setSecondButton(!secondButton);
  };

  return (
    <div>
      <div className="md:hidden fixed right-[20px] top-[394px] transition-all z-50">
        <ButtonArrow
          style="bg-mailGradient md:w-[56px] md:h-[65px] w-[44px] h-[52px]"
          onClick={() =>
            show(
              <InputForm
                titleSection={`${
                  firstButton
                    ? "Разработка программного  обеспечения"
                    : "Аренда программного обеспечения (SaaS)"
                }`}
              />,
              "",
              "md:modalInputForm modalInputFormMD"
            )
          }
        />
        <ButtonArrow
          style="bg-upSmallGradient md:w-[56px] md:h-[65px] w-[44px] h-[52px]"
          onClick={() =>
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            })
          }
        />
      </div>
      <div className="md:hidden block px-[20px] space-y-5">
        <div className="text-24-600-inter">Програмные решение</div>
        <ButtonDark
          label="Разработка программного  обеспечения"
          isActive={firstButton}
          onClick={buttonHandler}
        />
        <ButtonDark
          label="Аренда программного обеспечения (SaaS)"
          isActive={secondButton}
          onClick={buttonHandler}
        />
      </div>
      <SolutionsWrapper
        sectionFirst={
          <Section
            key="sectionSoftwareDevelopment1"
            section={sectionSoftwareDevelopment}
            buttons={[
              <Button
                key="sectionSoftwareDevelopmentApplication"
                label="Оставить заявку"
                style="w-[157px] 2xl:text-16-600-inter text-14-600-inter"
                onClick={() =>
                  show(
                    <InputForm
                      titleSection={sectionSoftwareDevelopment.title}
                    />,
                    "",
                    "md:modalInputForm modalInputFormMD"
                  )
                }
              />,
            ]}
            bgBig="bg-gray-700 bg-bgBlock4 bg-no-repeat bg-right-top h-full"
            bgSmall="md:bgDevelopment bgDevelopmentMD 2xl:pr-[86px] md:pr-[51px] pr-[20px]"
            isActive={firstButton}
            isCanOpen={true}
          />
        }
        sectionSecond={
          <Section
            key="sectionSoftwareRental"
            section={sectionSoftwareRental}
            buttons={[
              <ButtonLink
                key="sectionSoftwareRentaProduct"
                to={`${RoutesName.SOWTWARE_SOLUTIONS_PRODUCT}#top`}
                width="w-[157px]"
                label="Продукция"
                text="2xl:text-16-600-inter text-14-600-inter tracking-tighter"
              />,
              <Button
                key="sectionSoftwareRentaApplication"
                label="Оставить заявку"
                style="w-[157px] 2xl:text-16-600-inter text-14-600-inter"
                onClick={() =>
                  show(
                    <InputForm titleSection={sectionSoftwareRental.title} />,
                    "",
                    "md:modalInputForm modalInputFormMD"
                  )
                }
              />,
            ]}
            bgBig="bg-gray-700 h-full"
            bgSmall="bg-gray-300 2xl:pr-[86px] md:pr-[51px] pr-[20px]"
            isActive={secondButton}
            isCanOpen={true}
          />
        }
      />
    </div>
  );
}
