import React, { useState } from "react";
import SpoilerProductions, { ISpoilerItem } from "./SpoilerProduction";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";

export interface IProduct {
  title: string;
  description?: string[];
  list?: string[];
  note?: string;
  listNext?: string[];
  spoilers: ISpoilerItem[];
}

interface ISectionProps {
  section: IProduct;
  buttons: React.ReactNode[];
  bgBig: string;
  bgSmall: string;
  isBig?: boolean;
  isActive: boolean;
  isCanOpen: boolean;
}

export const Section: React.FC<ISectionProps> = ({
  section,
  buttons,
  bgBig,
  bgSmall,
  isBig,
  isActive,
  isCanOpen,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const isTabletOrMobile = useMediaQuery({
    query: "(min-width: 768px)",
  });
  const location = useLocation();

  const canOpen = isTabletOrMobile && isCanOpen && location.pathname === "/";

  return (
    <div className={`${isOpen ? "h-full" : ""}`}>
      {isActive && (
        <div
          className={`${bgBig} 2xl:rounded-3xl rounded-2xl flex flex-col h-full`}
        >
          <div
            onClick={() => setIsOpen(!isOpen)}
            className={`md:text-20-600-inter text-16-600-inter ${
              location.pathname === "/software/product/geophysics/gmkdigit"
                ? "tracking-[-1px]"
                : "tracking-[0.5px]"
            }  h-[76px] 2xl:pl-[88px] md:px-[51px] px-[20px] md:flex md:items-center hidden md:justify-between ${
              canOpen ? "cursor-pointer" : "pointer-events-none"
            }`}
          >
            <div>{section.title}</div>
            {canOpen && (
              <div
                className={`bg-arrow bg-center mt-[5px] h-4 w-4 bg-no-repeat transition duration-300 ${
                  isOpen && "rotate-180"
                } shrink-0`}
              ></div>
            )}
          </div>

          <div
            className={`${bgSmall} 2xl:rounded-3xl md:rounded-2xl rounded-t-2xl ${
              location.pathname === "/software/product/geophysics/gmkdigit"
                ? "pt-[0]"
                : "pt-[40px]"
            } 2xl:pb-10 pb-9 2xl:pl-[88px] md:pl-[51px] pl-[20px] flex flex-col basis-auto grow`}
          >
            {isOpen && (
              <div className="basis-auto grow 2xl:text-16-500-inter text-14-500-inter mb:mt-16 mb-[40px]">
                {section.description &&
                  section.description.map((item, index) => (
                    <div
                      key={index}
                      className="2xl:text-16-500-inter text-14-500-inter mt-[25px] first:mt-0"
                    >
                      {item}
                    </div>
                  ))}

                <ul className="list-none mt-6">
                  {section.list &&
                    section.list.map((item, index) => (
                      <li
                        key={index}
                        className="hexagonLi bg-li-small md:bg-li-md"
                      >
                        {item}
                      </li>
                    ))}
                </ul>
                <div className="mt-[17px] tracking-[0.1px]">{section.note}</div>
                {section.listNext?.length && (
                  <ul className="list-none mt-6">
                    {section.listNext.map((item, index) => (
                      <li
                        key={index}
                        className="hexagonLi bg-li-small md:bg-li-md"
                      >
                        {item}
                      </li>
                    ))}
                  </ul>
                )}

                {section.spoilers.map((item) => (
                  <SpoilerProductions
                    spoiler={item}
                    key={item.id}
                    isBig={isBig}
                  />
                ))}
              </div>
            )}
            <div className="flex md:justify-end justify-center flex-wrap  grow-0 gap-5 ">
              {buttons}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
