import React from "react";

interface IPartnerItemProps {
  partner: string;
}

export const PartnerItem: React.FC<IPartnerItemProps> = ({ partner }) => {
  return (
    <div className="shrink aspect-square bg-square bg-contain bg-no-repeat flex justify-center items-center">
      <div className="w-[80%] h-[80%] bg-white flex justify-center items-center rounded-lg border-blue border-solid border-2 ">
        <div
          className={`${partner} w-[102%] h-[102%] bg-no-repeat bg-contain bg-center rounded-lg`}
        ></div>
      </div>
    </div>
  );
};
