import React, { useContext, useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { Section } from "../components/Section";
import { SolutionsWrapper } from "../components/SolutionsWrapper";
import Button from "./../components/Button";
import { ModalContext } from "../context/modal/modalContext";
import { InputForm } from "../components/InputForm";
import { ButtonLink } from "./../components/ButtonLink";
import { EngineeringConsulting } from "./../components/EngineeringConsulting";
import { RoutesName } from "../components/AppRouter";
import ButtonDark from "../components/ButtonDark";
import ButtonArrow from "../components/ButtonArrow";

const sectionSystemIntegration = {
  title: "Системная интеграция",
  description: [
    "Мы реализуем комплексные мероприятия по созданию и защите ИТ-инфраструктуры предприятий и организаций, а также проектированию, внедрению и интеграции информационных систем.",
    "При построении корпоративной ИТ-инфраструктуры мы ориентируется на использование в первую очередь российского оборудования и ПО, сертифицированного в системе ФСТЭК России и ФСБ России, а также продуктов и технологий зарубежных ИКТ-компаний, не имеющих ограничений на работу на территории Российской Федерации.",
  ],
  note: "Услуги системной интеграции:",
  spoilers: [
    {
      id: 1,
      title: "Создание и модернизация дата-центров «под ключ»",
    },

    {
      id: 2,
      title:
        "Построение и развитие корпоративной ИТ-инфраструктуры и информационных систем",
    },
    {
      id: 3,
      title: "Внедрение интегрированных систем информационной безопасности",
    },
    {
      id: 4,
      title: "Создание и оптимизация систем унифицированных коммуникаций",
    },
    {
      id: 5,
      title:
        "Обеспечение инженерно-технической безопасности объектов и внутренних помещений",
    },
    {
      id: 6,
      title: "Создание инженерной инфраструктуры любого масштаба",
    },
    {
      id: 7,
      title:
        "Разработка стратегии развития ИТ и проведение комплексного ИТ-аудита",
    },
    {
      id: 8,
      title:
        "Сервисное обслуживание технических средств и информационных систем на протяжении всего их жизненного цикла",
    },
  ],
};

export function IntegrationSolutions() {
  const { show } = useContext(ModalContext);
  const [firstButton, setFirstButton] = useState<boolean>(true);
  const [secondButton, setSecondButton] = useState<boolean>(true);
  const isTabletOrMobile = useMediaQuery({
    query: "(min-width: 768px)",
  });

  useEffect(() => {
    if (isTabletOrMobile) {
      setFirstButton(true);
      setSecondButton(true);
    } else {
      setFirstButton(true);
      setSecondButton(false);
    }
  }, [isTabletOrMobile]);

  const buttonHandler = () => {
    setFirstButton(!firstButton);
    setSecondButton(!secondButton);
  };

  return (
    <div id="navbar1">
      <div className="md:hidden fixed right-[20px] top-[394px] transition-all z-50">
        <ButtonArrow
          style="bg-mailGradient md:w-[56px] md:h-[65px] w-[44px] h-[52px]"
          onClick={() =>
            show(
              <InputForm
                titleSection={`${
                  firstButton
                    ? "Системная интеграция"
                    : "Инжиниринг, консалтинг"
                }`}
              />,
              "",
              "md:modalInputForm modalInputFormMD"
            )
          }
        />
        <ButtonArrow
          style="bg-upSmallGradient md:w-[56px] md:h-[65px] w-[44px] h-[52px]"
          onClick={() =>
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            })
          }
        />
      </div>
      <div className="md:hidden block px-[20px] space-y-5">
        <div className="text-24-600-inter">Интеграционные решения</div>
        <ButtonDark
          label="Системная интеграция"
          isActive={firstButton}
          onClick={buttonHandler}
        />
        <ButtonDark
          label="Инжиниринг, консалтинг"
          isActive={secondButton}
          onClick={buttonHandler}
        />
      </div>
      <SolutionsWrapper
        sectionFirst={
          <Section
            section={sectionSystemIntegration}
            buttons={[
              <ButtonLink
                key="SystemIntegrationProduct"
                to={`${RoutesName.INTEGRATION_SOLUTIONS_PRODUCT}#top`}
                width="w-[157px]"
                label="Продукция"
                text="2xl:text-16-600-inter text-14-600-inter tracking-tighter"
              />,
              <Button
                key="SystemIntegrationApplication"
                label="Оставить заявку"
                style="w-[157px] 2xl:text-16-600-inter text-14-600-inter"
                onClick={() =>
                  show(
                    <InputForm titleSection={sectionSystemIntegration.title} />,
                    "",
                    "md:modalInputForm modalInputFormMD"
                  )
                }
              />,
            ]}
            bgBig="bg-gray-300 bg-bgBlock3 bg-no-repeat bg-left-top h-full"
            bgSmall="md:bgIntegration bgIntegrationMD 2xl:pr-[86px] md:pr-[51px] pr-[20px]"
            isActive={firstButton}
            isCanOpen={true}
          />
        }
        sectionSecond={
          <EngineeringConsulting
            buttons={[
              <Button
                key="EngineeringConsultingButton"
                label="Оставить заявку"
                style="w-[157px] 2xl:text-16-600-inter text-14-600-inter"
                onClick={() =>
                  show(
                    <InputForm titleSection="Инжиниринг, консалтинг" />,
                    "",
                    "md:modalInputForm modalInputFormMD"
                  )
                }
              />,
            ]}
            bgBig="bg-gray-300 h-full"
            bgSmall="bg-gray-700 2xl:pr-[86px] md:pr-[51px] pr-[20px]"
            isActive={secondButton}
          />
        }
      />
    </div>
  );
}
