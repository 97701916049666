import React, { useState, useEffect } from "react";
import { useLocation, NavLink } from "react-router-dom";
import { RoutesName } from "../components/AppRouter";
import { ButtonLink } from "./ButtonLink";
import { NavbarMobile } from "./NavbarMobile";

export function Navbar() {
  const [isMenuOpen, setMenuOpen] = useState<boolean>(false);

  const location = useLocation();

  useEffect(() => {
    setMenuOpen(false);
  }, [location.pathname]);

  useEffect(() => {
    if (isMenuOpen) {
      document.body.classList.add("lock");
    } else {
      document.body.classList.remove("lock");
    }
  }, [isMenuOpen]);

  const menuHandler = () => {
    setMenuOpen(!isMenuOpen);
  };

  const menuClose = () => {
    setMenuOpen(false);
  };

  return (
    <div>
      <div className="md:flex items-center justify-between space-x-5 hidden">
        <ButtonLink
          to={`${RoutesName.HARDWARE_SOLUTIONS}#top`}
          width="w-full"
          label="Аппаратные решения"
          text="h-[48px] 2xl:text-20-600-inter lg:text-18-600-inter text-14-600-inter tracking-tighter"
        />
        <ButtonLink
          to={`${RoutesName.INTEGRATION_SOLUTIONS}#top`}
          width="w-full"
          label="Интеграционные решения"
          text="h-[48px] 2xl:text-20-600-inter lg:text-18-600-inter text-14-600-inter tracking-tighter"
        />
        <ButtonLink
          to={`${RoutesName.SOWTWARE_SOLUTIONS}#top`}
          width="w-full"
          label="Программные решения"
          text="h-[48px] 2xl:text-20-600-inter lg:text-18-600-inter text-14-600-inter tracking-tighter"
        />
        <ButtonLink
          to={`${RoutesName.OUR_PROJECTS}#top`}
          width="w-full"
          label="Наши проекты"
          text="h-[48px] 2xl:text-20-600-inter lg:text-18-600-inter text-14-600-inter tracking-tighter"
        />
      </div>
      <div className="md:hidden  flex flex-col">
        <div
          className={`${
            location.pathname === "/"
              ? "hidden"
              : "flex justify-between items-center py-[15px] px-[35px]"
          }`}
        >
          <NavLink to="/">
            <div className=" bg-logo w-[37px] h-[30px] bg-no-repeat bg-cover"></div>
          </NavLink>

          <div onClick={menuHandler} className="cursor-pointer">
            <div
              className={`h-[2.25px] w-[26.25px] ${
                isMenuOpen ? "bg-whiteExtraLight" : "bg-white"
              }`}
            ></div>
            <div
              className={`h-[2.25px] w-[26.25px] ${
                isMenuOpen ? "bg-whiteExtraLight" : "bg-white"
              } mt-[6.75px]`}
            ></div>
            <div
              className={`h-[2.25px] w-[26.25px] ${
                isMenuOpen ? "bg-whiteExtraLight" : "bg-white"
              } mt-[6.75px]`}
            ></div>
          </div>
        </div>
        <div
          className={`${
            isMenuOpen ? "burgerActive" : "burgerHidden"
          } flex flex-col grow`}
        >
          <div className="grow mobileTop pt-[33%] pl-[23%] rounded-medium">
            <div className="text-24-500-montserrat">GMK</div>
            <div className="text-16-500-montserrat mt-[-10px] ml-[1px]">
              DIGIT
            </div>
          </div>
          <NavbarMobile menuClose={menuClose} />
        </div>
      </div>
    </div>
  );
}
