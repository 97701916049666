import { send } from "@emailjs/browser";
import { FieldValues } from "react-hook-form";

export const sendData = async (data: FieldValues) => {
  try {
    const payload = { ...data };
    delete payload.agreement;

    await send(
      "default_service",
      "template_z9x9dgj",
      payload,
      "IOyU3ngIHfvCLDBdv"
    );
  } catch (error: unknown) {
    throw new Error();
  }
};
