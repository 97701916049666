import React, { useContext } from "react";
import Button from "../components/Button";
import { ButtonChevron } from "../components/ButtonChevron";
import { InputForm } from "../components/InputForm";
import { Section } from "../components/Section";
import { SectionMicro5 } from "../components/SectionMicro5";
import { ModalContext } from "../context/modal/modalContext";
import { useNavigate } from "react-router-dom";
import { RoutesName } from "../components/AppRouter";

const gmkDigitProducts = [
  {
    title: "Микросейсмический мониторинг - Поколение IV",
    spoilers: [
      {
        id: 1,
        icon: "bg-learning bg-cover",
        title:
          "Модуль интерактивного управления системы обработки FracLoc (FL Flow)",
        subtitle:
          "Программа предназначена для визуализации, запуска и остановки процессов обработки",
        description: [
          "Программа предназначена для визуализации, запуска и остановки процессов обработки с целью предоставления пользователю удобного доступа к управлению вычислительными ресурсами.",
          "Программа обеспечивает выполнение следующих функций:",
        ],
        list: [
          "создание, удаление или копирование процессов обработки",
          "заполнение параметров процессов обработки",
          "запуск и остановка процессов обработки на вычислительном кластере",
          "контроль за выполнение процессов обработки",
          "внесение данных в систему обработки",
          "визуализацию сейсмической антенны",
        ],
      },
      {
        id: 2,
        icon: "bg-learning bg-cover",
        title:
          "Модуль просмотра и анализа микросейсмических записей системы обработки FracLoc (FL TraceAR)",

        subtitle:
          "Программа предназначена для визуализации микросейсмических записей",
        description: [
          "Программа предназначена для визуализации микросейсмических записей, полученных в результате полевых работ, с целью выявления помех техногенного характера.",
          "Программа обеспечивает выполнение следующих функций:",
        ],
        list: [
          "визуализацию сейсмических данных во времени",
          "построение спектрограмм",
          "расчет спектров в окне",
          "визуализацию сейсмической антенны",
          "визуализацию графиков технологических процессов",
          "редактирование сейсмических данных",
        ],
      },
      {
        id: 3,
        icon: "bg-learning bg-cover",
        title:
          "Модуль визуализации событий системы обработки FracLoc (FL 3dEventViewer)",
        subtitle:
          "Программа предназначена для визуализации микросейсмических событий",
        description: [
          "Программа предназначена для визуализации микросейсмических событий, полученных в результате обработки микросейсмических данных, с целью презентации результатов обработки в удобном и понятном виде.",
          "Программа предназначена для визуализации микросейсмических событий, полученных в результате обработки микросейсмических данных, с целью презентации результатов обработки в удобном и понятном виде.",
        ],
        list: [
          "визуализацию событий в 3D пространстве",
          "визуализацию событий в проекциях на плоскости",
          "проигрывание видеоролика возникновения событий по времени",
          "представление событий в виде шаров различных диаметров, набора главных осей напряжений и др",
          "визуализацию графиков технологических процессов",
          "визуализацию сейсмической антенны",
          "визуализацию траектории ствола скважины",
          "визуализацию горизонтов и разломов",
        ],
      },
      {
        id: 4,
        icon: "bg-learning bg-cover",
        title:
          "Модуль запуска графических программ системы обработки FracLoc (FL FLauncher)",
        subtitle:
          "Программа предназначена для запуска графических модулей системы обработки",
        description: [
          "Программа предназначена для запуска графических модулей системы обработки, запускаемых с параметрами процессов обработки, и является связующим звеном между серверной и клиентской частями системы обработки.",
          "Программа обеспечивает выполнение следующих функций:",
        ],
        list: [
          "загрузку входных данных для графических модулей",
          "запуск графического модуля с параметрами",
          "отслеживание процесса копирования данных",
        ],
      },
      {
        id: 5,
        icon: "bg-learning bg-cover",
        title:
          "Модуль проектирование сейсмической антенны системы обработки FracLoc (FL FieldPro)",
        subtitle:
          "Программа предназначена для проектирования сейсмической антенны",
        description: [
          "Программа предназначена для проектирования сейсмической антенны, предназначенной для микросейсмического мониторинга, с целью оптимизации затрат на развертывание расстановки при достижении необходимых точностных характеристик.",
          "Программа обеспечивает выполнение следующих функций:",
        ],
        list: [
          "нанесение точек приема, устья и проекцию траектории скважины на космоснимки",
          "определение точностных характеристик сейсмической антенны",
          "моделирование временных параметров источних",
          "моделирование амплитудных параметров источников",
          "редактирование местоположения точек приема сейсмической антенны",
        ],
      },
      {
        id: 6,
        icon: "bg-learning bg-cover",
        title:
          "Программа: Модуль конвертации бинарных данных системы обработки FracLoc (FL BIN)",
        subtitle:
          "Программа предназначена для конвертации сейсмических данных, хранящихся в бинарном виде",
        description: [
          "Программа предназначена для конвертации сейсмических данных, хранящихся в бинарном виде, с целью ввода сейсмических записей в систему обработки микросейсмических данных.",
          "Программа обеспечивает выполнение функции конвертации данных из бинарных данных во внутренний формат системы обработки микросейсмических данных.",
        ],
      },
      {
        id: 7,
        icon: "bg-learning bg-cover",
        title:
          "Программа: дуль конвертации данных со станции SGD системы обработки FracLoc (FL SGD)",
        subtitle:
          "Программа предназначена для конвертации сейсмических данных, снятых со станций SGD",
        description: [
          "Программа предназначена для конвертации сейсмических данных, снятых со станций SGD, с целью ввода сейсмических записей в систему обработки микросейсмических данных.",
          "Программа обеспечивает выполнение функции конвертации данных из внутреннего формата станций SGD во внутренний формат системы обработки микросейсмических данных.",
        ],
      },
      {
        id: 8,
        icon: "bg-learning bg-cover",
        title:
          "Модуль конвертации данных со станции scout системы обработки FracLoc (FL Scout)",
        subtitle:
          "Программа предназначена для конвертации сейсмических данных, снятых со станций scout",
        description: [
          "Программа предназначена для конвертации сейсмических данных, снятых со станций scout, с целью ввода сейсмических записей в систему обработки микросейсмических данных.",
          "Программа обеспечивает выполнение функции конвертации данных из внутреннего формата станций scout во внутренний формат системы обработки микросейсмических данных.",
        ],
      },
      {
        id: 9,
        icon: "bg-learning bg-cover",
        title:
          "Модуль полосоно-заграждающей фильтрации системы обработки FracLoc (FL BandStopFilter)",
        subtitle:
          "Программа предназначена для полосовой фильтрации помех, имеющих техногенное происхождение",
        description: [
          "Программа предназначена для полосовой фильтрации помех, имеющих техногенное происхождение, с целью повышения соотношения сигнал/шум при проведении процедур обработки микросейсмических данных и повышения надежности обнаружения микросейсмических событий.",
          "Программа обеспечивает выполнение следующих функций:",
        ],
        list: [
          "полосовая фильтрация помех",
          "полосовая фильтрация помех",
          "повышения надежности обнаружения микросейсмических событий",
        ],
      },
      {
        id: 10,
        icon: "bg-learning bg-cover",
        title:
          "Модуль полосоно-пропускающей фильтрации системы обработки FracLoc (FL BandPassFilter)",
        subtitle:
          "Программа предназначена для полосовой фильтрации помех, имеющих техногенное происхождение",
        description: [
          "Программа предназначена для полосовой фильтрации помех, имеющих техногенное происхождение, с целью повышения соотношения сигнал/шум при проведении процедур обработки микросейсмических данных и повышения надежности обнаружения микросейсмических событий.",
          "Программа обеспечивает выполнение следующих функций:",
        ],
        list: [
          "полосовая фильтрация низкочастотных и высокочастотных помех",
          "повышения соотношения сигнал/шум",
          "повышения надежности обнаружения микросейсмических событий",
        ],
      },
      {
        id: 11,
        icon: "bg-learning bg-cover",
        title:
          "Модуль режекторной фильтрации системы обработки FracLoc (FL NotchFilter)",
        subtitle:
          "Программа предназначена для выделения и фильтрации гармонических помех, имеющих техногенное происхождение",
        description: [
          "Программа предназначена для выделения и фильтрации гармонических помех, имеющих техногенное происхождение, с целью повышения соотношения сигнал/шум при проведении процедур обработки микросейсмических данных и повышения надежности обнаружения микросейсмических событий.",
          "Программа обеспечивает выполнение следующих функций:",
        ],
        list: [
          "выделение гармонических помех",
          "фильтрация гармонических помех",
          "повышения соотношения сигнал/шум",
          "повышения надежности обнаружения микросейсмических событий",
        ],
      },
      {
        id: 12,
        icon: "bg-learning bg-cover",
        title:
          "Модуль расчета годографа микросейсмических событий системы обработки FracLoc (FL ATime)",
        subtitle:
          "Программа предназначена для выделения в сейсмическом поле микросейсмических событий, имеющих глубинное происхождение",
        description: [
          "Программа предназначена для выделения в сейсмическом поле микросейсмических событий, имеющих глубинное происхождение.",
          "Программа обеспечивает выполнение следующих функций:",
        ],
        list: [
          "определение времени возникновения микросейсмических событий",
          "определение годографа микросейсмических событий",
          "определение амплитуд микросейсмических событий",
        ],
      },
      {
        id: 13,
        icon: "bg-learning bg-cover",
        title:
          "Модуль расчета параметров событий системы обработки FracLoc (FL KICore)",
        subtitle:
          "Программа предназначена для расчета кинематических и динамических параметров микросейсмических событий, имеющих глубинное происхождение",
        description: [
          "Программа предназначена для расчета кинематических и динамических параметров микросейсмических событий, имеющих глубинное происхождение, с целью определения гипоцентров событий, тензора сейсмического момента и его разложений.",
          "Программа обеспечивает выполнение следующих функций:",
        ],
        list: [
          "определение гипоцентра микросейсмического события",
          "определение тензора сейсмического момента",
          "определение главных направлений тензора сейсмического момента и значений вдоль этих направлений",
          "разложение тензора сейсмического момента на составляющие ISO, DC, CLVD",
        ],
      },
      {
        id: 14,
        icon: "bg-learning bg-cover",
        title:
          "Модуль плотностной фильтрации событий системы обработки FracLoc (FL EventFilter)",
        subtitle:
          "Программа предназначена для расчета различных атрибутов плотности событий",
        description: [
          "Программа предназначена для расчета различных атрибутов плотности событий, таких как плотность энергии, плотность скопления и др, с целью выделения событий, образующих плотные скопления.",
          "Программа обеспечивает выполнение следующих функций:",
        ],
        list: [
          "расчет плотностных параметров",
          "фильтрация событий по атрибутама",
        ],
      },
      {
        id: 15,
        icon: "bg-learning bg-cover",
        title:
          "Модуль моделирования микросейсмических событий системы обработки FracLoc (FL Syn)",
        subtitle:
          "Программа предназначена для моделирования распространения сейсмических волн от источников в 1D/3D средах",
        description: [
          "Программа предназначена для моделирования распространения сейсмических волн от источников в 1D/3D средах, с целью получения характеристик сигналов, прошедших через 1D/3D среду, и построения синтетических сейсмограмм.",
          "Программа обеспечивает выполнение следующих функций:",
        ],
        list: [
          "моделирование сигналов от микросейсмических событий",
          "построение синтетических сейсмограмм",
          "моделирование шума",
        ],
      },
      {
        id: 16,
        icon: "bg-learning bg-cover",
        title:
          "Модуль адаптивной фильтрации системы обработки FracLoc (FL Adapt)",
        subtitle:
          "Программа предназначена для выделения и фильтрации квазигармонических помех",
        description: [
          "Программа предназначена для выделения и фильтрации квазигармонических помех, имеющих техногенное происхождение, с целью повышения соотношения сигнал/шум при проведении процедур обработки микросейсмических данных и повышения надежности обнаружения микросейсмических событий.",
          "Программа обеспечивает выполнение следующих функций:",
        ],
        list: [
          "выделение квазигармонических помех",
          "фильтрация квазигармонических помех",
          "повышения соотношения сигнал/шум",
          "повышения надежности обнаружения микросейсмических событий",
        ],
      },
    ],
  },
];
const MicroV = {
  title: "Микросейсмический мониторинг - Поколение V",

  spoilers: [
    {
      id: 1,
      icon: "bg-union bg-contain",
      title:
        "MicroSeismic Monitoring - Interpolation of Forward Solver (MSM_IFS)",
      subtitle:
        "Программа интерполяции прямой задачи микросейсмического мониторинга",
      description: [
        "MicroSeismic Monitoring - Interpolation of Forward Solver (MSM_IFS) предназначена для ускорения решения задачи распространения продольной волны в рамках лучевого приближения в слоистых упругих средах. Ускорение расчетов достигается за счет использования кусочно-полиномиальной функции, которая аппроксимирует точное решение с априорно заданным уровнем погрешности.",
        "Программа обеспечивает выполнение следующих функций:",
      ],
      list: [
        { style: "", order: "1.", text: "чтение файла настроек" },
        { style: "", order: "2.", text: "загрузка геомеханической модели" },
        {
          style: "",
          order: "3.",
          text: "загрузка области предполагаемого нахождения источников",
        },
        {
          style: "",
          order: "4.",
          text: "загрузка координат микросейсмической антенны",
        },
        {
          style: "",
          order: "5.",
          text: "расчет и сохранение параметров кусочно-полиномиальных функций, которые аппроксимируют:",
        },
        {
          style: "ml-[20px]",
          order: "•",
          text: "время распространения возмущений от источника к приемнику",
        },
        {
          style: "ml-[20px]",
          order: "•",
          text: "направление (направляющие косинусы) прохождения луча через приемник",
        },
        {
          style: "ml-[20px]",
          order: "•",
          text: "коэффициент затухания амплитуды луча в процессе распространения",
        },
      ],
    },

    {
      id: 2,
      icon: "bg-union bg-contain",
      title: "MicroSeismic Monitoring - Picking of First Break (MSM_PFB)",
      subtitle:
        "Программа определения первых вступлений микросейсмических событий",
      description: [
        "MicroSeismic Monitoring - Picking of First Break (MSM_PFB) предназначена для выделения микросейсмических событий из зарегистрированного волнового поля. Программа рассчитывает суммотрассу, функцию энергии от времени. Значение функции равно максимальному значению энергии допустимого события. Область поиска параметров события включает координаты гипоцентров событий и тензор сейсмического момента. На основе суммотрассы осуществляется выделение событий. Алгоритм, реализованный в программе, позволяет определить импульс предполагаемого события, времена вступлений и амплитуды на каждом канале.",
        "Программа обеспечивает выполнение следующих функций:",
      ],
      list: [
        { style: "", order: "1.", text: "чтение файла настроек" },
        {
          style: "",
          order: "2.",
          text: "загрузка области предполагаемого нахождения источников",
        },
        {
          style: "",
          order: "3.",
          text: "загрузка координат микросейсмической антенны",
        },
        {
          style: "",
          order: "4.",
          text: "загрузка данных для расчета параметров распространения сейсмических волн (выходные файлы программы MicroSeismic Monitoring - Interpolation of Forward Solver)",
        },
        {
          style: "",
          order: "5.",
          text: "расчет суммотрассы максимальных энергий предполагаемых микросейсмических событий",
        },
        {
          style: "",
          order: "6.",
          text: "расчет и сохранение параметров мощнейших предполагаемых микросейсмических событий",
        },
        {
          style: "ml-[20px]",
          order: "•",
          text: "время первого вступлений на каждом канале",
        },
        {
          style: "ml-[20px]",
          order: "•",
          text: "амплитуда сигнала на каждом канале",
        },
        {
          style: "ml-[20px]",
          order: "•",
          text: "импульс микросейсмического события",
        },
      ],
    },
    {
      id: 3,
      icon: "bg-union bg-contain",
      title: "MicroSeismic Monitoring - Inverse Problem Solver (MSM_IPS)",
      subtitle:
        "Программа решения обратной задачи микросейсмического мониторинга",
      description: [
        "MicroSeismic Monitoring - Inverse Problem Solver (MSM_IPS) предназначена для вычисления параметров микросейсмических событий. Вычисляемые параметры: гипоцентр и магнитуда микросейсмических событий, тензор сейсмического момента и его разложения на ISO, DC и CLVD. Алгоритм основан на минимизации функционала невязки раздельно для кинематической и динамической задачи.",
        "Программа обеспечивает выполнение следующих функций:",
      ],
      list: [
        { style: "", order: "1.", text: "чтение файла настроек" },
        {
          style: "",
          order: "2.",
          text: "загрузка области предполагаемого нахождения источников",
        },
        {
          style: "",
          order: "3.",
          text: "загрузка координат микросейсмической антенны",
        },
        {
          style: "",
          order: "4.",
          text: "загрузка модели распространения сейсмических волн",
        },
        {
          style: "",
          order: "5.",
          text: "загрузка данных рассчитанных программой определения первых вступлений микросейсмических событий MicroSeismic Monitoring - Picking of First Break (MSM_PFB), а именно:",
        },

        {
          style: "ml-[20px]",
          order: "•",
          text: "время первого вступлений на каждом канале",
        },
        {
          style: "ml-[20px]",
          order: "•",
          text: "амплитуда сигнала на каждом канале",
        },
        {
          style: "ml-[20px]",
          order: "•",
          text: "импульс микросейсмического события",
        },
        {
          style: "",
          order: "6.",
          text: "расчет и сохранение параметров микросейсмических событий:",
        },
        {
          style: "ml-[20px]",
          order: "•",
          text: "координаты гипоцентра микросейсмического события",
        },
        {
          style: "ml-[20px]",
          order: "•",
          text: "время возникновения события",
        },
        {
          style: "ml-[20px]",
          order: "•",
          text: "компоненты тензор сейсмического мемента",
        },
        {
          style: "ml-[20px]",
          order: "•",
          text: "коэффициенты разложения тензора сейсмического момента на ISO, DC и CLVD",
        },
      ],
    },
    {
      id: 4,
      icon: "bg-union bg-contain",
      title:
        "MicroSeismic Monitoring – Calibration of Model and Data (MSM_CMD)",
      subtitle: "Программа калибровки модели и данных",
      description: [
        "MicroSeismic Monitoring – Calibration of Model and Data (MSM_CMD) предназначена для определения поправок для геомеханической модели и/или микросейсмических записей. Поправки рассчитываются, основываясь на априорной информации: гипоцентр и тип источника. Программа рассчитывает суммотрассу, функцию энергии от времени, максимизировав которую, вычисляются поправки. Используемый алгоритм позволяет определить кинематические и амплитудные поправки, наклон слоистой модели.",
        "Программа обеспечивает выполнение следующих функций:",
      ],
      list: [
        { style: "", order: "1.", text: "чтение файла настроек" },
        {
          style: "",
          order: "2.",
          text: "чтение файла с выделенными событиями",
        },
        {
          style: "",
          order: "3.",
          text: "загрузка координат антенны",
        },
        {
          style: "",
          order: "4.",
          text: "координаты гипоцентра калибровочных источников",
        },

        {
          style: "",
          order: "5.",
          text: "расчет и сохранение поправок",
        },
      ],
    },
    {
      id: 5,
      icon: "bg-union bg-contain",
      title: "Modelling of Field Exploration (MSM_MFE)",
      subtitle: "Программа проектирования системы наблюдения",
      description: [
        "MicroSeismic Monitoring – Modelling of Field Exploration (MSM_MFE) предназначена для решения задачи оптимального расположения системы наблюдения микросейсмического мониторинга. В зависимости от поставленных задач перед мониторингом применяются скважинные, наземные или гибридные системы наблюдения.  Для принятия решения о типе, количестве и месте размещения необходимого оборудования программа предоставляет следующие функции:",
      ],
      list: [
        {
          style: "",
          order: "1.",
          text: "моделирование кинематических и динамических параметров волн, вызванных микросейсмическим событием",
        },
        {
          style: "",
          order: "2.",
          text: "совмещение координат датчиков и космоснимков для оценки возможности размещения оборудования",
        },
        {
          style: "",
          order: "3.",
          text: "расчёт точности локации микросейсмического события (как по глубине, так и в горизонтальной плоскости) в зависимости от модели окружающей среды, координат и типов датчиков, модели кинематических ошибок, погрешности измерения координат датчиков",
        },
        {
          style: "",
          order: "4.",
          text: "визуализация расчетов в виде карт и разрезов с привязкой к местоположению датчиков и траектории скважин",
        },
      ],
    },
  ],
};

export function SoftwareSolutionsProductGeophysicsGMK() {
  const navigate = useNavigate();

  const { show } = useContext(ModalContext);
  return (
    <div className="relative">
      <div className="md:block hidden absolute w-[100%] aspect-[1/1] md:bg-sofwareSolutionGeoBG  bg-no-repeat bg-cover bottom-[-60px]  -z-[1]"></div>
      <div className="md:pt-[40px] pt-5 md:pb-5 md:px-0 px-5 md:flex md:space-x-5 md:space-y-0 space-y-5">
        <ButtonChevron
          label="LandOcean EPoffice"
          labelChevron="CH"
          width="md:w-[49.5%] w-full"
          onClick={() =>
            navigate(
              RoutesName.SOWTWARE_SOLUTIONS_PRODUCT_GEOPHYSICS_LAND_OCEAN
            )
          }
          active={false}
          styleChevron="bg-chevronCh"
        />
        <ButtonChevron
          label="ГМК ЦИФРА"
          labelChevron="RU"
          width="md:w-[49.5%] w-full"
          onClick={() =>
            navigate(RoutesName.SOWTWARE_SOLUTIONS_PRODUCT_GEOPHYSICS_GMK_DIGIT)
          }
          active={true}
          styleChevron="bg-chevronRu"
        />
      </div>
      <div>
        <div className="mt-[20px]">
          <Section
            section={gmkDigitProducts[0]}
            buttons={[
              <Button
                key={1}
                label="Оставить заявку"
                style="w-[157px] 2xl:text-16-600-inter text-14-600-inter"
                onClick={() =>
                  show(
                    <InputForm titleSection={gmkDigitProducts[0].title} />,
                    "",
                    "md:modalInputForm modalInputFormMD"
                  )
                }
              />,
            ]}
            bgBig="bg-gray-300"
            bgSmall="md:bgMicroIV bgMicroIVMD 2xl:pr-[88px] md:pr-[51px] pr-[20px]"
            isBig={true}
            isActive={true}
            isCanOpen={false}
          />
          <div className="mt-[37px]">
            <SectionMicro5
              section={MicroV}
              buttons={[
                <Button
                  key={2}
                  label="Оставить заявку"
                  style="w-[157px] 2xl:text-16-600-inter text-14-600-inter"
                  onClick={() =>
                    show(
                      <InputForm titleSection={`SaaS:${MicroV.title}`} />,
                      "",
                      "md:modalInputForm modalInputFormMD"
                    )
                  }
                />,
              ]}
              bgBig="bg-gray-300"
              bgSmall="md:bgMicroV bgMicroVMD 2xl:pr-[88px] md:pr-[51px] pr-[20px]"
            />
          </div>
        </div>
        {/* )} */}
      </div>
    </div>
  );
}
