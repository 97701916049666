import React, { useState } from "react";
import { useLocation } from "react-router-dom";

interface IEngineeringConsultingProps {
  buttons: React.ReactNode[];
  bgBig: string;
  bgSmall: string;
  isActive: boolean;
}

const list = [
  "Оптимизация и автоматизация бизнес-процессов с целью повышения управляемости и рентабельности деятельности предприятия",
  "Оптимизация производственных и логистических операций",
  "Повышение эффективности труда",
  "Повышение производительности и утилизации оборудования",
  "Повышение эффективности НИОКР и разработки продуктов",
  "Снижение расхода ресурсов и производственных потерь",
  "Сокращение издержек за счет производственного и управленческого персонала;",
  "Увеличение скорости принятия решений",
];

export const EngineeringConsulting: React.FC<IEngineeringConsultingProps> = ({
  buttons,
  bgBig,
  bgSmall,
  isActive,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const location = useLocation();

  return (
    <div>
      {isActive && (
        <div
          className={`${bgBig} 2xl:rounded-3xl rounded-2xl h-full flex flex-col`}
        >
          <div
            onClick={() => setIsOpen(!isOpen)}
            className={`md:text-20-700-advent tracking-[0.5px] h-[76px] 2xl:px-[68px] px-[51px] md:flex md:items-center hidden justify-between ${
              location.pathname === "/"
                ? "cursor-pointer"
                : "pointer-events-none"
            }`}
          >
            <div>Инжиниринг, консалтинг</div>
            {location.pathname === "/" && (
              <div
                className={`bg-arrow bg-center mt-[5px] h-4 w-4 bg-no-repeat transition duration-300 ${
                  isOpen && "rotate-180"
                } shrink-0`}
              ></div>
            )}
          </div>

          <div
            className={`${bgSmall} 2xl:rounded-3xl rounded-2xl 2xl:pt-10  md:pt-[38px] pt-[24px] 2xl:pb-10 pb-9 2xl:px-[68px] md:px-[51px] px-[20px] flex flex-col basis-auto grow relative`}
          >
            {isOpen && (
              <div className="mb-16">
                <div className="md:block hidden w-[336px] h-[442px] bg-rounds1 bg-cover bg-no-repeat absolute top-[28%] left-[-6%]"></div>
                <div className="md:block hidden w-[336px] h-[442px] bg-rounds2 bg-cover bg-no-repeat absolute bottom-[14%] right-[0]"></div>

                <div className="md:hidden block w-[292px] h-[267px] bg-triangle2 bg-contain bg-no-repeat absolute sm:top-[34.5%] top-[39%] md:right-[-5%] right-0"></div>
                <div className="md:hidden block w-[364px] h-[327px] bg-triangle3 bg-contain bg-no-repeat absolute bottom-[18%] left-0"></div>

                <div className="basis-auto grow 2xl:text-16-500-inter text-14-500-inter z-10">
                  <div>
                    Мы видим цифровизацию промышленности как источник роста
                    бизнеса через:
                  </div>

                  <ul className="mt-6 list-none pr-[30px]">
                    {list.map((item, index) => (
                      <li
                        key={`${index}number`}
                        className="hexagonLiTextIndent bg-li md:bg-li-md z-10"
                      >
                        {item}
                      </li>
                    ))}
                  </ul>
                  <div className="mt-[37px] relative z-10">
                    Что мы предлагаем:
                    <div className="md:hidden block w-[130px] h-[149px] bg-triangle1 bg-cover bg-no-repeat absolute top-[252%] left-[-2%]"></div>
                  </div>
                  <div className="md:text-14-500-inter text-12-500-inter md:mt-[49px] mt-[20px]">
                    <div className="md:ml-[14%] ml-[30%] z-10 relative">
                      Производственные подразделения
                    </div>

                    <div className="flex md:mt-[40px] mt-[20px] relative">
                      <div className="md:text-16-600-inter text-14-500-inter w-[107px] md:mt-[7px] mt-[13px] z-10 relative">
                        Анализ, оценка и экспертиза
                      </div>
                      <div>
                        <div className="ml-[10%] z-10 relative">
                          Сервисные подразделения (снабжение, хранение,
                          реализация, управление и др.)
                        </div>
                        <div className="md:ml-[15%] ml-[10%] md:mt-[38px] mt-[20px] z-10 relative">
                          Этапы создания стоимости
                        </div>
                        <div className="md:ml-[15%] ml-[10%] md:mt-[40px] mt-[20px] z-10 relative">
                          Объекты инфраструктуры производства с целью их
                          автоматизации
                        </div>
                        <div className="md:ml-[1%] ml-[10%] md:mt-[40px] mt-[20px] z-10 relative">
                          Структура издержек и продукции
                        </div>
                      </div>
                    </div>
                    <div className="md:mr-[10%] mr-[32%] md:mt-[68px] mt-[35px] text-right z-10 relative">
                      Создание автоматизированного предприятия
                    </div>
                    <div className="flex md:mt-[46px] mt-[20px] z-10 relative">
                      <div>
                        <div className="md:mr-[11%] mr-[13%] text-right z-10 relative">
                          Возможность управления всеми бизнес-процессами
                          (планирование, поставка сырья, производство, хранение,
                          реализация, анализ результатов) удаленно
                        </div>
                        <div className="md:mt-[53px] mt-[20px] mr-[13%] text-right z-10 relative">
                          Оперативное получение информации и принятие
                          бизнес-решений за счет сбора и анализа всех
                          производственных и экономических показателей в
                          специализированном программном обеспечении
                        </div>
                        <div className="md:mt-[56px] mt-[20px] md:mr-[2%] mr-[13%] text-right z-10 relative">
                          Разработка и внедрение ERP системы для повышения
                          оперативности и эффективности принимаемых решений
                        </div>
                      </div>
                      <div className="md:text-16-600-inter text-14-500-inter mt-[110px] text-right relative">
                        <div className="md:hidden block w-[97px] h-[115px] bg-triangle4 bg-cover bg-no-repeat absolute top-[-38px] right-[15px] z-10"></div>
                        Решаемые задачи
                      </div>
                    </div>
                    <div className="mt-[40px] md:mr-[2%] mr-[32%] text-right z-10">
                      Сокращение издержек за счет внедрения передовых методов
                      управления, в том числе: «Бережливое производство» (Lean
                      Production), «Непрерывное улучшение» (Кайдзен), Balanced
                      scorecards или KPI
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="flex md:justify-end justify-center gap-5 grow-0 z-10">
              {buttons}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
