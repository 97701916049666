import React, { useState, useEffect, useContext } from "react";
import { useMediaQuery } from "react-responsive";
import AppRouter from "./components/AppRouter";
import { Navbar } from "./components/Navbar";
import { Modal } from "./components/Modal";
import { Footer } from "./components/Footer";
import { TopBlock } from "./components/TopBlock";
import ButtonArrow from "./components/ButtonArrow";
import { InputForm } from "./components/InputForm";
import { ModalContext } from "./context/modal/modalContext";

function App() {
  const { show } = useContext(ModalContext);
  const isTabletOrMobile = useMediaQuery({
    query: "(min-width: 768px)",
  });
  const [isShowLogo, setIsShowLogo] = useState(!isTabletOrMobile);

  useEffect(() => {
    setTimeout(() => {
      setIsShowLogo(false);
    }, 700);
  }, []);

  return (
    <div className="wrapper">
      {isShowLogo && (
        <div className="bg-mobileFirst h-[100vh] w-[100vw] bg-no-repeat bg-center bg-contain"></div>
      )}

      <div>
        {!isShowLogo && (
          <div>
            <div className="z-20 fixed right-[3px] top-[82vh] md:inline-flex hidden flex-col">
              <ButtonArrow
                style="bg-mailGradient md:w-[56px] md:h-[65px] w-[44px] h-[52px]"
                onClick={() =>
                  show(
                    <InputForm titleSection="Главная" />,
                    "",
                    "md:modalInputForm modalInputFormMD"
                  )
                }
              />
              <ButtonArrow
                style="bg-upSmallGradient md:w-[56px] md:h-[65px] w-[44px] h-[52px]"
                onClick={() =>
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  })
                }
              />
            </div>
            <TopBlock />
            <Modal />
            <div
              id="navbar"
              className="md:pt-[40px] container-width:px-[0] md:px-[10px] p-0"
            >
              <Navbar />
              <AppRouter />
            </div>
            <Footer />
          </div>
        )}
      </div>
    </div>
  );
}

export default App;
