import React, { useContext } from "react";
import { ModalContext } from "../context/modal/modalContext";
import { IIntegrationSolutionsProductItem } from "../pages/IntegrationSolutionsProduct";
import { IntegrationSolutionsProductItem } from "./IntegrationSolutionsProductItem";
import Button from "./Button";
import { InputForm } from "./InputForm";

interface ISectionIntegrationSolutionsProductProps {
  items: IIntegrationSolutionsProductItem[];
  bgStyle: string;
  titleSelection: string;
}

const SectionIntegrationSolutionsProduct = ({
  items,
  bgStyle,
  titleSelection,
}: ISectionIntegrationSolutionsProductProps) => {
  const { show } = useContext(ModalContext);
  return (
    <div
      className={`md:basis-[49.5%] md:mt-[40px] 2xl:px-[88px] md:px-[60px] px-[20px] 2xl:py-[28px] py-[20px] ${bgStyle}  rounded-3xl`}
    >
      <div className="text-24-700-advent">Продукция</div>
      <div className="mt-5 lg:mb-0 mb-5 flex flex-wrap justify-between 2xl:max-w-[1027px] max-w-full">
        {items.map((item, index) => (
          <IntegrationSolutionsProductItem
            key={index}
            title={item.title}
            list={item.list}
            icon={item.icon}
            id={item.id}
          />
        ))}
      </div>
      <Button
        label="Оставить заявку"
        style="w-[157px] md:text-16-600-inter text-14-600-inter md:ml-auto md:mr-0 mx-auto"
        onClick={() =>
          show(
            <InputForm titleSection={titleSelection} />,
            "",
            "md:modalInputForm modalInputFormMD"
          )
        }
      />
    </div>
  );
};

export default SectionIntegrationSolutionsProduct;
