import React from "react";

interface ISolutionsProps {
  sectionFirst: React.ReactNode;
  sectionSecond: React.ReactNode;
}

export const SolutionsWrapper: React.FC<ISolutionsProps> = ({
  sectionFirst,
  sectionSecond,
}) => {
  return (
    <div>
      <div className="flex xl:flex-nowrap flex-wrap xl:space-x-[20px]  items-stretch md:pt-0 pt-5">
        <div className="xl:basis-[50%] basis-[100%] grow-0 shrink md:pt-5">
          {sectionFirst}
        </div>
        <div className="xl:basis-[50%] basis-[100%] grow-0 shrink md:pt-5">
          {sectionSecond}
        </div>
      </div>
    </div>
  );
};
