import React, { useReducer } from "react";
import { ModalContext } from "./modalContext";
import { ActionsModal, modalReducer } from "./modalReducer";

interface IModalStateProps {
  children: React.ReactNode;
}

const ModalState: React.FC<IModalStateProps> = ({ children }) => {
  const [state, dispatch] = useReducer(modalReducer, { active: false });

  const show = (node: React.ReactNode, title: string, wrapperStyle: string) => {
    dispatch({
      type: ActionsModal.SHOW_MODAL,
      payload: { node, title, wrapperStyle },
    });
  };

  const hide = () => {
    dispatch({ type: ActionsModal.HIDE_MODAL });
  };

  return (
    <ModalContext.Provider value={{ show, hide, modal: state }}>
      {children}
    </ModalContext.Provider>
  );
};

export default ModalState;
