import React from "react";
import { UseFormRegister, FieldValues } from "react-hook-form";

interface IInputProps {
  label: string;
  id: string;
  register: UseFormRegister<FieldValues>;
}

const Textarea: React.FC<IInputProps> = ({ label, id, register }) => {
  return (
    <div className="relative">
      <label className="block text-16-500-inter text-left" htmlFor="email">
        {label}
      </label>

      <textarea
        {...register(id)}
        className="h-[175px] w-full px-8 pt-3 pb-2 mt-1 text-16-500-inter rounded-xl bg-transparent border-gray-500 border-solid border-2 outline-none hover:border-gray-600 caret-white active:border-white focus:border-white focus:ring-0"
        id={id}
      />
    </div>
  );
};

export default Textarea;
