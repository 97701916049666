import React, { useContext } from "react";
import { ModalContext } from "../context/modal/modalContext";
import Button from "./Button";
import { InputForm } from "./InputForm";

interface IApplicationAcceptedProps {
  titleSection: string;
}

const WriteUs: React.FC<IApplicationAcceptedProps> = ({ titleSection }) => {
  const { show } = useContext(ModalContext);
  return (
    <div className="modalWriteUs xl:basis-[37%] md:basis-[40%] basis-[100%] sm:grow-0 grow shrink h-full sm:mt-[38px] mt-[20px] md:mx-0 mx-[20px]">
      <div className="2xl:text-16-500-inter md:text-14-500-inter text-12-500-inter xl:mt-[67px] mt-[45px] xl:pr-[120px] md:pl-[9px] pl-[0] tracking-[0.1px]">
        Компания оператор платформы предлагает партнерство сервис-провайдерам
        цифровых услуг и решений.
      </div>
      <div className="2xl:text-16-500-inter md:text-14-500-inter text-12-500-inter mt-[23px] md:mb-[40px] mb-[20px] tracking-[0.1px] md:pl-[9px] pl-[0]">
        Напишите нам!
      </div>

      <Button
        style="w-[156px] h-[56px] 2xl:text-16-600-inter text-14-600-inter"
        onClick={() => {
          show(
            <InputForm titleSection={titleSection} />,
            "",
            "md:modalInputForm modalInputFormMD"
          );
        }}
        label="Написать"
      />
    </div>
  );
};

export default WriteUs;
