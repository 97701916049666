import React, { useContext } from "react";
import { ButtonChevron } from "../components/ButtonChevron";
import SectionIntegrationSolutionsProduct from "../components/SectionIntegrationSolutionsProduct";
import { ModalContext } from "../context/modal/modalContext";
import ButtonArrow from "./../components/ButtonArrow";
import { InputForm } from "./../components/InputForm";

export interface IIntegrationSolutionsProductItem {
  id: number;
  title: string;
  list: string[];
  icon: string;
}

const integrationSolutionsProductItems = [
  {
    id: 1,
    title: "X86 Сервер",
    list: ["Стоечные серверы", "Блейд серверы"],
    icon: "bg-server",
  },

  {
    id: 2,
    title: "Системы хранения данных",
    list: ["Серверы хранения данных", "Дисковые массивы", "Облачное хранение"],
    icon: "bg-storage",
  },

  {
    id: 3,
    title: "Передовые вычисления",
    list: ["Серверы передовых вычислений", "Передовое вычислительные решения"],
    icon: "bg-computing",
  },

  {
    id: 4,
    title: "Инфраструктура",
    list: ["Решения по охлаждению"],
    icon: "bg-infrastructure",
  },
  {
    id: 5,
    title: "Глубокое обучение",
    list: ["Системы глубокого обучения/нейронные сети"],
    icon: "bg-learning",
  },
];

export function IntegrationSolutionsProduct() {
  const { show } = useContext(ModalContext);

  return (
    <div className="md:pt-[38px] pt-[20px]">
      <div className="md:hidden fixed right-[20px] top-[394px] transition-all z-50">
        <ButtonArrow
          style="bg-mailGradient md:w-[56px] md:h-[65px] w-[44px] h-[52px]"
          onClick={() =>
            show(
              <InputForm titleSection="Системная интеграция" />,
              "",
              "md:modalInputForm modalInputFormMD"
            )
          }
        />
        <ButtonArrow
          style="bg-upSmallGradient md:w-[56px] md:h-[65px] w-[44px] h-[52px]"
          onClick={() =>
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            })
          }
        />
      </div>
      <div className="md:px-0 px-[20px] ">
        <ButtonChevron
          label="Sugon"
          labelChevron="CH"
          width="md:w-[49.5%] w-full"
          onClick={() => {
            return;
          }}
          active={true}
          styleChevron="bg-chevronCh"
        />
      </div>
      <div className="flex xl:flex-nowrap flex-wrap xl:space-x-[20px]">
        <SectionIntegrationSolutionsProduct
          items={integrationSolutionsProductItems}
          bgStyle="md:bgIntegrationSolutionsProduct bgIntegrationSolutionsProductMD"
          titleSelection="Системная интеграция"
        />
        {/* <SectionIntegrationSolutionsProduct
          items={integrationSolutionsProductItems}
          bgStyle="bg-gray-300"
          titleSelection="Системная интеграция"
        /> */}
      </div>
    </div>
  );
}
