import React, { useState, useContext } from "react";
import Select, { StylesConfig } from "react-select";
import Button from "./Button";
import { getCurrentSummary } from "./../common/utils";
import { ModalContext } from "../context/modal/modalContext";
import { InputForm } from "./InputForm";

export type ICalculatorItem = {
  id: number;
  title: string;
  description: string;
  price: number;
  open: boolean;
  selected: boolean;
};

type TOption = {
  value: string;
  label: string;
  count?: number;
};

const locations: TOption[] = [
  { value: "MoskowKurchatova", label: "Москва Курчатова" },
  { value: "MoskowKurchatova-2", label: "Москва Курчатова-2" },
  { value: "Novosibirsk", label: "Новосибирск-2" },
  { value: "SaintPetersburg", label: "Санкт-Петербург" },
];

const typesSystemDisk: TOption[] = [
  { value: "SAS", label: "SAS" },
  { value: "SATA", label: "SATA" },
  { value: "SSD", label: "SSD" },
];

const productivitySSD: TOption[] = [
  { value: "1 IOPS/GB", label: "1 IOPS/GB", count: 1 },
  { value: "3 IOPS/GB", label: "3 IOPS/GB", count: 3 },
  { value: "5 IOPS/GB", label: "5 IOPS/GB", count: 5 },
  { value: "10 IOPS/GB", label: "10 IOPS/GB", count: 10 },
];

const OperatingSystems: TOption[] = [
  {
    value: "Ubuntu server 14.04 (64-bit)",
    label: "Ubuntu server 14.04 (64-bit)",
  },
  { value: "Centos 6.8 (64-bit)", label: "Centos 6.8 (64-bit)" },
  { value: "Centos 7.3", label: "Centos 7.3" },
  { value: "RHL 7.3", label: "RHL 7.3" },
  { value: "RHL 6.8", label: "RHL 6.8" },
  {
    value: "Windows Server 2016 Standart EN",
    label: "Windows Server 2016 Standart EN",
  },
  {
    value: "Windows Server 2016 Standart RU",
    label: "Windows Server 2016 Standart RU",
  },
  {
    value: "Windows Server 2019 Standart EN",
    label: "Windows Server 2019 Standart EN",
  },
  {
    value: "Windows Server 2019 Standart RU",
    label: "Windows Server 2019 Standart RU",
  },
  { value: "Centos 7.9 lvm (x86-64)", label: "Centos 7.9 lvm (x86-64)" },
  { value: "Centos 8.4 (x86-64)", label: "Centos 8.4 (x86-64)" },
  { value: "Centos 8.4 lvm (x86-64)", label: "Centos 8.4 lvm (x86-64)" },
  { value: "Ubuntu 16.04 (x86-64)", label: "Ubuntu 16.04 (x86-64)" },
  { value: "Ubuntu 16.04 lvm (x86-64)", label: "Ubuntu 16.04 lvm (x86-64)" },
  { value: "Ubuntu 18.04 (x86-64)", label: "Ubuntu 18.04 (x86-64)" },
  { value: "Centos 8 lvm (x86-64)", label: "Centos 8 lvm (x86-64)" },
];
type TProtsessor = {
  minValue: number;
  maxValue: number;
  price: number;
};

type TSystemDiskSpace = {
  [key: string]: TProtsessor;
};

interface ILocPrice {
  protsessor: TProtsessor;
  ram: TProtsessor;
  ip: TProtsessor;
  systemDiskSpace: TSystemDiskSpace;
  productivityPrice: number;
}

export interface IPrice {
  [key: string]: ILocPrice;
}

const price: IPrice = {
  MoskowKurchatova: {
    protsessor: {
      minValue: 1,
      maxValue: 64,
      price: 19.4,
    },
    ram: {
      minValue: 1,
      maxValue: 128,
      price: 17.4,
    },
    ip: {
      minValue: 0,
      maxValue: 2,
      price: 3.5,
    },
    systemDiskSpace: {
      SAS: {
        minValue: 0,
        maxValue: 1000,
        price: 0.35,
      },
      SATA: {
        minValue: 0,
        maxValue: 1000,
        price: 0.94,
      },
      SSD: {
        minValue: 0,
        maxValue: 1000,
        price: 0.35,
      },
    },
    productivityPrice: 0.2,
  },
  "MoskowKurchatova-2": {
    protsessor: {
      minValue: 1,
      maxValue: 64,
      price: 19.4,
    },
    ram: {
      minValue: 1,
      maxValue: 128,
      price: 17.4,
    },
    ip: {
      minValue: 0,
      maxValue: 2,
      price: 3.5,
    },
    systemDiskSpace: {
      SAS: {
        minValue: 0,
        maxValue: 1000,
        price: 0.35,
      },
      SATA: {
        minValue: 0,
        maxValue: 1000,
        price: 0.94,
      },
      SSD: {
        minValue: 0,
        maxValue: 1000,
        price: 0.35,
      },
    },
    productivityPrice: 0.2,
  },
  Novosibirsk: {
    protsessor: {
      minValue: 1,
      maxValue: 64,
      price: 19.4,
    },
    ram: {
      minValue: 1,
      maxValue: 128,
      price: 17.4,
    },
    ip: {
      minValue: 0,
      maxValue: 2,
      price: 3.5,
    },
    systemDiskSpace: {
      SAS: {
        minValue: 0,
        maxValue: 1000,
        price: 0.35,
      },
      SATA: {
        minValue: 0,
        maxValue: 1000,
        price: 0.94,
      },
      SSD: {
        minValue: 0,
        maxValue: 1000,
        price: 0.35,
      },
    },
    productivityPrice: 0.2,
  },
  SaintPetersburg: {
    protsessor: {
      minValue: 1,
      maxValue: 64,
      price: 19.4,
    },
    ram: {
      minValue: 1,
      maxValue: 128,
      price: 17.4,
    },
    ip: {
      minValue: 0,
      maxValue: 2,
      price: 3.5,
    },
    systemDiskSpace: {
      SAS: {
        minValue: 0,
        maxValue: 1000,
        price: 0.35,
      },
      SATA: {
        minValue: 0,
        maxValue: 1000,
        price: 0.94,
      },
      SSD: {
        minValue: 0,
        maxValue: 1000,
        price: 0.35,
      },
    },
    productivityPrice: 0.2,
  },
};

const customStylesLocation: StylesConfig<TOption, false> = {
  menu: (provided) => ({
    ...provided,
    background: "#363951",
    border: "2px solid #3E405B",
    borderRadius: "4px",
    marginTop: "20px",
  }),

  container: (provided) => ({
    ...provided,
    border: "2px solid #3E405B",
    borderRadius: "4px",
  }),

  valueContainer: (provided) => ({
    ...provided,
    paddingLeft: 24,
    position: "relative",
    "&:before": {
      position: "absolute",
      content: "''",
      top: "4px",
      left: "0px",
      width: "14px",
      height: "20px",
      background: `url(${require("./../assets/location.png")})`,
    },
  }),

  option: (provided, state) => ({
    ...provided,
    cursor: "pointer",
    background: state.isSelected
      ? "linear-gradient(180deg, #3643FC 0%, #44488B 100%)"
      : "#363951",
    "&:hover": {
      background: "linear-gradient(180deg, #04CDEC 0%, #3643FC 100%)",
    },
    padding: "8px 24px 8px 39px",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "24px",
    color: "rgba(255, 255, 255)",
    borderRadius: "4px",
  }),
  control: (provided, state) => ({
    ...provided,
    cursor: "pointer",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#FFFFFF",
    paddingLeft: "16px",
    background: "#363951",
    border: state.isFocused ? "0" : "0",
    boxShadow: "none",
  }),
  singleValue: (provided) => ({
    ...provided,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#FFFFFF",
  }),
  indicatorSeparator: () => ({}),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    paddingRight: "15px",
    svg: {
      fill: "#ffffff",
      transition: "all 0.3s cubic-bezier(0.4, 0, 0.2, 1)",
      transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : "null",
    },
  }),
};
const customStylesTypesSystemDisk: StylesConfig<TOption, false> = {
  menu: (provided) => ({
    ...provided,
    borderTop: "2px solid #28293D",
    background: "#3E405B",
    borderRadius: "4px",
    marginTop: "0px",
  }),
  container: (provided) => ({
    ...provided,
    borderTop: "2px solid #363951",
    borderBottom: "1px solid rgba(199, 198, 198, 0.1)",
  }),

  valueContainer: (provided) => ({
    ...provided,
    padding: "55px 0 16px 0",
    position: "relative",
    "&:before": {
      position: "absolute",
      content: "'Тип системного диска'",
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "16px",
      lineHeight: "24px",
      color: "rgba(255, 255, 255, 0.8)",
      top: "21px",
      left: "0px",
    },
  }),

  option: (provided, state) => ({
    ...provided,
    cursor: "pointer",
    background: state.isSelected
      ? "linear-gradient(180deg, #3643FC 0%, #44488B 100%)"
      : "#3E405B",
    "&:hover": {
      background: "linear-gradient(180deg, #04CDEC 0%, #3643FC 100%)",
    },
    padding: "8px 24px 8px 21px",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "24px",
    color: "rgba(255, 255, 255)",
    borderRadius: "4px",
  }),
  control: (provided, state) => ({
    ...provided,
    cursor: "pointer",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#FFFFFF",
    padding: "0 0 0 18px",
    background: "#3E405B",
    border: state.isFocused ? "0" : "0",
    boxShadow: "none",
  }),
  singleValue: (provided) => ({
    ...provided,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#FFFFFF",
  }),
  indicatorSeparator: () => ({}),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    padding: "5px 47px 0 0",
    svg: {
      fill: "#ffffff",
      transition: "all 0.3s cubic-bezier(0.4, 0, 0.2, 1)",
      transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : "null",
    },
  }),
};
const customStylesOperatingSystems: StylesConfig<TOption, false> = {
  menu: (provided) => ({
    ...provided,
    borderTop: "2px solid #28293D",
    background: "#3E405B",
    borderRadius: "4px",
    marginTop: "0px",
  }),
  container: (provided) => ({
    ...provided,
    backgroundPosition: "center",
    backgroundSize: "contain",
    borderBottom: "2px solid #363951",
  }),

  valueContainer: (provided) => ({
    ...provided,
    padding: "55px 0 16px 0",
    position: "relative",
    "&:before": {
      position: "absolute",
      content: "'Операционная система'",
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "16px",
      lineHeight: "24px",
      color: "rgba(255, 255, 255, 0.8)",
      top: "21px",
      left: "0px",
    },
  }),

  option: (provided, state) => ({
    ...provided,
    cursor: "pointer",
    background: state.isSelected
      ? "linear-gradient(180deg, #3643FC 0%, #44488B 100%)"
      : "#3E405B",
    "&:hover": {
      background: "linear-gradient(180deg, #04CDEC 0%, #3643FC 100%)",
    },
    padding: "8px 24px 8px 21px",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "24px",
    color: "rgba(255, 255, 255)",
    borderRadius: "4px",
  }),
  control: (provided, state) => ({
    ...provided,
    cursor: "pointer",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#FFFFFF",
    padding: "0 0 0 18px",
    background: "#3E405B",
    border: state.isFocused ? "0" : "0",
    boxShadow: "none",
  }),
  singleValue: (provided) => ({
    ...provided,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#FFFFFF",
  }),
  indicatorSeparator: () => ({}),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    padding: "5px 47px 0 0",
    svg: {
      fill: "#ffffff",
      transition: "all 0.3s cubic-bezier(0.4, 0, 0.2, 1)",
      transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : "null",
    },
  }),
};

const customStylesProductivity: StylesConfig<TOption, false> = {
  menu: (provided) => ({
    ...provided,
    borderTop: "2px solid #28293D",
    background: "#3E405B",
    borderRadius: "4px",
    marginTop: "0px",
  }),
  container: (provided) => ({
    ...provided,
  }),

  valueContainer: (provided) => ({
    ...provided,
    padding: "55px 0 16px 0",
    position: "relative",
    "&:before": {
      position: "absolute",
      content: "'Производительность'",
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "16px",
      color: "rgba(255, 255, 255, 0.8)",
      top: "21px",
      left: "0px",
    },
  }),

  option: (provided, state) => ({
    ...provided,
    cursor: "pointer",
    background: state.isSelected
      ? "linear-gradient(180deg, #3643FC 0%, #44488B 100%)"
      : "#3E405B",
    "&:hover": {
      background: "linear-gradient(180deg, #04CDEC 0%, #3643FC 100%)",
    },
    padding: "8px 24px 8px 21px",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "24px",
    color: "rgba(255, 255, 255)",
    borderRadius: "4px",
  }),
  control: (provided, state) => ({
    ...provided,
    cursor: "pointer",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#FFFFFF",
    padding: "0 0 0 18px",
    background: "#3E405B",
    border: state.isFocused ? "0" : "0",
    boxShadow: "none",
  }),
  singleValue: (provided) => ({
    ...provided,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    color: "rgba(255, 255, 255, 0.8)",
  }),
  indicatorSeparator: () => ({}),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    padding: "5px 47px 0 0",
    ["svg"]: {
      fill: "#ffffff",
      transition: "all 0.3s cubic-bezier(0.4, 0, 0.2, 1)",
      transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : "null",
    },
  }),
};

export function Calculator() {
  const { show } = useContext(ModalContext);
  const [summary, setSummary] = useState<number>(36.8);
  const [selectedOptionLocation, setSelectedOptionLocation] =
    useState<TOption | null>(locations[0]);

  const [selectedOptionTypesSystemDisk, setSelectedOptionTypesSystemDisk] =
    useState<TOption | null>(typesSystemDisk[0]);

  const [selectedOptionOperatingSystems, setSelectedOptionOperatingSystems] =
    useState<TOption | null>(OperatingSystems[0]);

  const [selectedOptionProductivity, setSelectedOptionProductivity] =
    useState<TOption | null>(productivitySSD[0]);

  const [protsessor, setProtsessor] = useState<string>("1");
  const [ram, setRam] = useState<string>("1");
  const [ip, setIp] = useState<string>("0");
  const [systemDiskSpace, setSystemDiskSpace] = useState<string>("0");

  const selectLocationsHandler = (option: TOption | null) => {
    const currentSelectedLocations = option ? option.value : "default";
    setSelectedOptionLocation(option);
    const currentTypesSystemDisk: string = selectedOptionTypesSystemDisk
      ? selectedOptionTypesSystemDisk.value
      : "default";
    const currentSummary: number = getCurrentSummary(
      price,
      currentSelectedLocations,
      +protsessor,
      +ram,
      +ip,
      +systemDiskSpace,
      currentTypesSystemDisk,
      selectedOptionProductivity?.count || 1
    );

    setSummary(currentSummary);
  };

  const selectTypesSystemDiskHandler = (option: TOption | null) => {
    const currentSelectedLocations = selectedOptionLocation
      ? selectedOptionLocation.value
      : "default";

    const currentTypesSystemDisk: string = option ? option.value : "default";

    setSelectedOptionTypesSystemDisk(option);

    const currentSummary: number = getCurrentSummary(
      price,
      currentSelectedLocations,
      +protsessor,
      +ram,
      +ip,
      +systemDiskSpace,
      currentTypesSystemDisk,
      selectedOptionProductivity?.count || 1
    );

    setSummary(currentSummary);
  };

  const selectOperatingSystemsHandler = (option: TOption | null) => {
    setSelectedOptionOperatingSystems(option);
  };

  const selectProductivityHandler = (option: TOption | null) => {
    const currentSelectedLocations = selectedOptionLocation
      ? selectedOptionLocation.value
      : "default";

    const currentTypesSystemDisk: string = selectedOptionTypesSystemDisk
      ? selectedOptionTypesSystemDisk.value
      : "default";
    setSelectedOptionProductivity(option);
    const currentSummary: number = getCurrentSummary(
      price,
      currentSelectedLocations,
      +protsessor,
      +ram,
      +ip,
      +systemDiskSpace,
      currentTypesSystemDisk,
      option?.count || 1
    );
    setSummary(currentSummary);
  };

  const protsessorHandler = (event: React.FormEvent<HTMLInputElement>) => {
    const currentSelectedLocations = selectedOptionLocation
      ? selectedOptionLocation.value
      : "default";
    const currentTypesSystemDisk: string = selectedOptionTypesSystemDisk
      ? selectedOptionTypesSystemDisk.value
      : "default";
    const currentProtsessor: number =
      +event.currentTarget.value >
      price[currentSelectedLocations].protsessor.maxValue
        ? price[currentSelectedLocations].protsessor.maxValue
        : +event.currentTarget.value <
          price[currentSelectedLocations].protsessor.minValue
        ? price[currentSelectedLocations].protsessor.minValue
        : Math.ceil(+event.currentTarget.value);

    const currentSummary: number = getCurrentSummary(
      price,
      currentSelectedLocations,
      +currentProtsessor,
      +ram,
      +ip,
      +systemDiskSpace,
      currentTypesSystemDisk,
      selectedOptionProductivity?.count || 1
    );

    setProtsessor(String(currentProtsessor));
    setSummary(currentSummary);
  };

  const ramHandler = (event: React.FormEvent<HTMLInputElement>) => {
    const currentSelectedLocations = selectedOptionLocation
      ? selectedOptionLocation.value
      : "default";
    const currentTypesSystemDisk: string = selectedOptionTypesSystemDisk
      ? selectedOptionTypesSystemDisk.value
      : "default";
    const currentRam: number =
      +event.currentTarget.value > price[currentSelectedLocations].ram.maxValue
        ? price[currentSelectedLocations].ram.maxValue
        : +event.currentTarget.value <
          price[currentSelectedLocations].ram.minValue
        ? price[currentSelectedLocations].ram.minValue
        : +event.currentTarget.value;

    const currentSummary: number = getCurrentSummary(
      price,
      currentSelectedLocations,
      +protsessor,
      +currentRam,
      +ip,
      +systemDiskSpace,
      currentTypesSystemDisk,
      selectedOptionProductivity?.count || 1
    );
    setRam(String(currentRam));
    setSummary(currentSummary);
  };

  const ipHandler = (event: React.FormEvent<HTMLInputElement>) => {
    const currentSelectedLocations = selectedOptionLocation
      ? selectedOptionLocation.value
      : "default";
    const currentTypesSystemDisk: string = selectedOptionTypesSystemDisk
      ? selectedOptionTypesSystemDisk.value
      : "default";
    const currentIp: number =
      +event.currentTarget.value > price[currentSelectedLocations].ip.maxValue
        ? price[currentSelectedLocations].ip.maxValue
        : +event.currentTarget.value <
          price[currentSelectedLocations].ip.minValue
        ? price[currentSelectedLocations].ip.minValue
        : +event.currentTarget.value;
    const currentSummary: number = getCurrentSummary(
      price,
      currentSelectedLocations,
      +protsessor,
      +ram,
      +currentIp,
      +systemDiskSpace,
      currentTypesSystemDisk,
      selectedOptionProductivity?.count || 1
    );
    setIp(String(currentIp));
    setSummary(currentSummary);
  };

  const systemDiskSpaceHandler = (event: React.FormEvent<HTMLInputElement>) => {
    const currentSelectedLocations = selectedOptionLocation
      ? selectedOptionLocation.value
      : "default";
    const currentTypesSystemDisk: string = selectedOptionTypesSystemDisk
      ? selectedOptionTypesSystemDisk.value
      : "default";
    const currentSystemDiskSpace: number =
      +event.currentTarget.value > 1000
        ? 1000
        : +event.currentTarget.value < 0
        ? 0
        : +event.currentTarget.value;
    const currentSummary: number = getCurrentSummary(
      price,
      currentSelectedLocations,
      +protsessor,
      +ram,
      +ip,
      +currentSystemDiskSpace,
      currentTypesSystemDisk,
      selectedOptionProductivity?.count || 1
    );
    setSystemDiskSpace(String(currentSystemDiskSpace));
    setSummary(currentSummary);
  };

  const incrementProtsessor = () => {
    const currentSelectedLocations = selectedOptionLocation
      ? selectedOptionLocation.value
      : "default";
    const currentTypesSystemDisk: string = selectedOptionTypesSystemDisk
      ? selectedOptionTypesSystemDisk.value
      : "default";
    if (
      +protsessor + 1 <=
      price[currentSelectedLocations].protsessor.maxValue
    ) {
      setProtsessor(String(+protsessor + 1));

      const currentSummary: number = getCurrentSummary(
        price,
        currentSelectedLocations,
        +protsessor + 1,
        +ram,
        +ip,
        +systemDiskSpace,
        currentTypesSystemDisk,
        selectedOptionProductivity?.count || 1
      );

      setSummary(currentSummary);
    }
  };

  const decrementProtsessor = () => {
    const currentSelectedLocations = selectedOptionLocation
      ? selectedOptionLocation.value
      : "default";
    const currentTypesSystemDisk: string = selectedOptionTypesSystemDisk
      ? selectedOptionTypesSystemDisk.value
      : "default";
    if (
      +protsessor - 1 >=
      price[currentSelectedLocations].protsessor.minValue
    ) {
      setProtsessor(String(+protsessor - 1));
      const currentSummary: number = getCurrentSummary(
        price,
        currentSelectedLocations,
        +protsessor - 1,
        +ram,
        +ip,
        +systemDiskSpace,
        currentTypesSystemDisk,
        selectedOptionProductivity?.count || 1
      );
      setSummary(currentSummary);
    }
  };

  const incrementRam = () => {
    const currentSelectedLocations = selectedOptionLocation
      ? selectedOptionLocation.value
      : "default";
    const currentTypesSystemDisk: string = selectedOptionTypesSystemDisk
      ? selectedOptionTypesSystemDisk.value
      : "default";
    if (+ram + 1 <= price[currentSelectedLocations].ram.maxValue) {
      setRam(String(+ram + 1));

      const currentSummary: number = getCurrentSummary(
        price,
        currentSelectedLocations,
        +protsessor,
        +ram + 1,
        +ip,
        +systemDiskSpace,
        currentTypesSystemDisk,
        selectedOptionProductivity?.count || 1
      );

      setSummary(currentSummary);
    }
  };

  const decrementRam = () => {
    const currentSelectedLocations = selectedOptionLocation
      ? selectedOptionLocation.value
      : "default";
    const currentTypesSystemDisk: string = selectedOptionTypesSystemDisk
      ? selectedOptionTypesSystemDisk.value
      : "default";
    if (+ram - 1 >= price[currentSelectedLocations].ram.minValue) {
      setRam(String(+ram - 1));

      const currentSummary: number = getCurrentSummary(
        price,
        currentSelectedLocations,
        +protsessor,
        +ram - 1,
        +ip,
        +systemDiskSpace,
        currentTypesSystemDisk,
        selectedOptionProductivity?.count || 1
      );
      setSummary(currentSummary);
    }
  };

  const incrementIp = () => {
    const currentSelectedLocations = selectedOptionLocation
      ? selectedOptionLocation.value
      : "default";
    const currentTypesSystemDisk: string = selectedOptionTypesSystemDisk
      ? selectedOptionTypesSystemDisk.value
      : "default";
    if (+ip + 1 <= price[currentSelectedLocations].ip.maxValue) {
      setIp(String(+ip + 1));
      const currentSummary: number = getCurrentSummary(
        price,
        currentSelectedLocations,
        +protsessor,
        +ram,
        +ip + 1,
        +systemDiskSpace,
        currentTypesSystemDisk,
        selectedOptionProductivity?.count || 1
      );

      setSummary(currentSummary);
    }
  };

  const decrementIp = () => {
    const currentSelectedLocations = selectedOptionLocation
      ? selectedOptionLocation.value
      : "default";
    const currentTypesSystemDisk: string = selectedOptionTypesSystemDisk
      ? selectedOptionTypesSystemDisk.value
      : "default";
    if (+ip - 1 >= price[currentSelectedLocations].ip.minValue) {
      setIp(String(+ip - 1));

      const currentSummary: number = getCurrentSummary(
        price,
        currentSelectedLocations,
        +protsessor,
        +ram,
        +ip - 1,
        +systemDiskSpace,
        currentTypesSystemDisk,
        selectedOptionProductivity?.count || 1
      );
      setSummary(currentSummary);
    }
  };

  const incrementSystemDiskSpace = () => {
    const currentSelectedLocations = selectedOptionLocation
      ? selectedOptionLocation.value
      : "default";
    const currentTypesSystemDisk: string = selectedOptionTypesSystemDisk
      ? selectedOptionTypesSystemDisk.value
      : "default";
    if (+systemDiskSpace + 1 <= 1000) {
      setSystemDiskSpace(String(+systemDiskSpace + 1));

      const currentSummary: number = getCurrentSummary(
        price,
        currentSelectedLocations,
        +protsessor,
        +ram,
        +ip,
        +systemDiskSpace + 1,
        currentTypesSystemDisk,
        selectedOptionProductivity?.count || 1
      );

      setSummary(currentSummary);
    }
  };

  const decrementSystemDiskSpace = () => {
    const currentSelectedLocations = selectedOptionLocation
      ? selectedOptionLocation.value
      : "default";
    const currentTypesSystemDisk: string = selectedOptionTypesSystemDisk
      ? selectedOptionTypesSystemDisk.value
      : "default";
    if (+systemDiskSpace - 1 >= 0) {
      setSystemDiskSpace(String(+systemDiskSpace - 1));
      const currentSummary: number = getCurrentSummary(
        price,
        currentSelectedLocations,
        +protsessor,
        +ram,
        +ip,
        +systemDiskSpace - 1,
        currentTypesSystemDisk,
        selectedOptionProductivity?.count || 1
      );

      setSummary(currentSummary);
    }
  };

  return (
    <div className="md:w-[418px]">
      <div className="mt-[37px]">
        <Select
          defaultValue={selectedOptionLocation}
          onChange={selectLocationsHandler}
          options={locations}
          styles={customStylesLocation}
          isSearchable={false}
        />
      </div>
      <div className="bg-gray-700 mt-5  rounded-3xl  py-[18px]">
        <div className="px-[18px]">
          <div className="text-16-600-inter-light ">
            Процессор Intel Xeon, ядра
          </div>
          <div className="flex justify-between items-center mt-[2px]">
            <input
              className="text-16-600-inter caret-white shadow-none bg-gray-700 border-none outline-0 focus:outline-gray-700 focus:ring-0 pl-0"
              type="number"
              value={protsessor}
              min={
                price[
                  selectedOptionLocation
                    ? selectedOptionLocation.value
                    : "default"
                ].protsessor.minValue
              }
              max={
                price[
                  selectedOptionLocation
                    ? selectedOptionLocation.value
                    : "default"
                ].protsessor.maxValue
              }
              onChange={(event) => {
                setProtsessor(event.currentTarget.value);
              }}
              onBlur={protsessorHandler}
              onKeyDown={(event) => {
                if (event.code === "ArrowUp" || event.code === "ArrowDown") {
                  event.preventDefault();
                }
              }}
            />
            <div className="space-x-5">
              <button
                className="text-20-600-inter "
                onClick={decrementProtsessor}
              >
                -
              </button>
              <button
                className="text-20-600-inter "
                onClick={incrementProtsessor}
              >
                +
              </button>
            </div>
          </div>

          <div className="text-14-500-inter-gray mt-1">
            Частота ядра 3.1 ГГц
          </div>
        </div>
        <div className="mt-[15px] px-[18px]">
          <div className="text-16-600-inter-light ">Оперативная память, ГБ</div>
          <div className="flex justify-between items-center mt-[7px]">
            <input
              className="text-16-600-inter caret-white shadow-none bg-gray-700 border-none outline-0 focus:outline-gray-700 focus:ring-0 pl-0"
              type="number"
              value={ram}
              min={
                price[
                  selectedOptionLocation
                    ? selectedOptionLocation.value
                    : "default"
                ].ram.minValue
              }
              max={
                price[
                  selectedOptionLocation
                    ? selectedOptionLocation.value
                    : "default"
                ].ram.maxValue
              }
              onChange={(event) => {
                setRam(event.currentTarget.value);
              }}
              onBlur={ramHandler}
              onKeyDown={(event) => {
                if (event.code === "ArrowUp" || event.code === "ArrowDown") {
                  event.preventDefault();
                }
              }}
            />
            <div className="space-x-5">
              <button className="text-20-600-inter " onClick={decrementRam}>
                -
              </button>
              <button className="text-20-600-inter " onClick={incrementRam}>
                +
              </button>
            </div>
          </div>
        </div>
        <div className="mt-2 px-[18px]">
          <div className="text-16-600-inter-light ">
            Объем системного диска, ГБ
          </div>
          <div className="flex justify-between items-center mt-1">
            <input
              className="text-16-600-inter caret-white shadow-none bg-gray-700 border-none outline-0 focus:outline-gray-700 focus:ring-0 pl-0"
              type="number"
              value={systemDiskSpace}
              min={0}
              max={1000}
              step={1}
              onChange={(event) => {
                setSystemDiskSpace(event.currentTarget.value);
              }}
              onBlur={systemDiskSpaceHandler}
              onKeyDown={(event) => {
                if (event.code === "ArrowUp" || event.code === "ArrowDown") {
                  event.preventDefault();
                }
              }}
            />
            <div className="space-x-5">
              <button
                className="text-20-600-inter "
                onClick={decrementSystemDiskSpace}
              >
                -
              </button>
              <button
                className="text-20-600-inter "
                onClick={incrementSystemDiskSpace}
              >
                +
              </button>
            </div>
          </div>
        </div>
        <div className="mt-[7px]">
          <Select
            defaultValue={selectedOptionTypesSystemDisk}
            onChange={selectTypesSystemDiskHandler}
            options={typesSystemDisk}
            styles={customStylesTypesSystemDisk}
            isSearchable={false}
          />
        </div>
        <div>
          {selectedOptionTypesSystemDisk?.value === "SAS" && (
            <div className="px-5 pt-5 pb-[15px] text-16-600-inter-light">
              <div>Производительность</div>
              <div className="mt-[12px]">До 1000 IOPS на машину</div>
            </div>
          )}

          {selectedOptionTypesSystemDisk?.value === "SATA" && (
            <div className="px-5 pt-[22px] pb-[14px] text-16-600-inter-light">
              <div>Производительность</div>
              <div className="mt-[12px]">До 400 IOPS на машину</div>
            </div>
          )}
          {selectedOptionTypesSystemDisk?.value === "SSD" && (
            <Select
              defaultValue={selectedOptionProductivity}
              onChange={selectProductivityHandler}
              options={productivitySSD}
              styles={customStylesProductivity}
              isSearchable={false}
            />
          )}
        </div>
        <svg width="100%" height="1">
          <line
            x1="0"
            y1="0"
            x2="100%"
            y2="0"
            stroke="#C7C6C6"
            strokeDasharray="4"
          />
        </svg>
        <div>
          <Select
            defaultValue={selectedOptionOperatingSystems}
            onChange={selectOperatingSystemsHandler}
            options={OperatingSystems}
            styles={customStylesOperatingSystems}
            isSearchable={false}
          />
        </div>
        <div className="mt-[22px] px-[18px]">
          <div className="text-16-600-inter-light ">IP-адрес</div>
          <div className="flex justify-between items-center mt-[2px]">
            <input
              className="text-16-600-inter caret-white shadow-none bg-gray-700 border-none outline-0 focus:outline-gray-700 focus:ring-0 pl-0"
              type="number"
              value={ip}
              min={
                price[
                  selectedOptionLocation
                    ? selectedOptionLocation.value
                    : "default"
                ].ip.minValue
              }
              max={
                price[
                  selectedOptionLocation
                    ? selectedOptionLocation.value
                    : "default"
                ].ip.maxValue
              }
              onChange={(event) => {
                setIp(event.currentTarget.value);
              }}
              onBlur={ipHandler}
              onKeyDown={(event) => {
                if (event.code === "ArrowUp" || event.code === "ArrowDown") {
                  event.preventDefault();
                }
              }}
            />
            <div className="space-x-5">
              <button className="text-20-600-inter " onClick={decrementIp}>
                -
              </button>
              <button className="text-20-600-inter " onClick={incrementIp}>
                +
              </button>
            </div>
          </div>
        </div>
        <div className="ml-4 mt-[15px]">
          <span className="text-32-700-inter">{`${summary.toFixed(2)} ₽`}</span>{" "}
          <span className="text-16-600-inter">в сутки</span>
        </div>
        <div className="mt-[35px] ml-[15px]">
          <Button
            label="Оставить заявку"
            style="w-[146px]  2xl:h-[40px]  2xl:text-16-600-inter text-14-600-inter"
            onClick={() =>
              show(
                <InputForm titleSection="Аренда ИТ-инфраструктуры (IaaS)" />,
                "",
                "md:modalInputForm modalInputFormMD"
              )
            }
          />
        </div>
      </div>
    </div>
  );
}
