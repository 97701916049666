import React from "react";

type IListItem = {
  style: string;
  order: string;
  text: string;
};

export type ISpoilerMicroVItem = {
  id: number;
  icon?: string;
  title: string;
  subtitle?: string;
  description?: string[];
  list?: IListItem[];
};

interface ISpoilerProductionMicroVProps {
  spoiler: ISpoilerMicroVItem;
}

const SpoilerProductionMicroV: React.FC<ISpoilerProductionMicroVProps> = ({
  spoiler,
}) => {
  return (
    <div className="mt-18px">
      <details
        className={`group ${!spoiler.description && "pointer-events-none"}`}
      >
        <summary className="pt-[30px] pr-[30px] pb-[27px] pl-[23px] group-open:pb-[13px] flex items-center justify-between  cursor-pointer bg-gray-800 group-open:rounded-b-none group-open:bg-gray-450">
          <div className="flex items-center space-x-4">
            {spoiler.icon && (
              <div
                className={`${spoiler.icon} h-[46px] w-[46px] bg-center bg-no-repeat shrink-0 md:block hidden`}
              ></div>
            )}
            <div>
              <div className="2xl:text-24-600-inter md:text-20-600-inter text-14-600-inter tracking-[-1.2px] leading-8 pr-5">
                {spoiler.title}
              </div>
              <div className="2xl:text-20-600-inter md:text-16-600-inter text-12-600-inter tracking-[-1px]  md:mt-[0] mt-[10px] pr-5">
                {spoiler.subtitle}
              </div>
            </div>
          </div>

          <div
            className={`${
              spoiler.description
                ? "bg-arrowBig bg-center mb-[10px]  h-4 w-4 bg-no-repeat transition duration-300 group-open:rotate-180 shrink-0 grow-0"
                : "hidden"
            }`}
          ></div>
        </summary>
        <div className="2xl:text-16-500-inter-light md:text-14-600-inter-light text-12-500-interLight tracking-[-0.9px] md:pr-[80px] pr-[40px] md:pl-[86px] pl-[40px] pb-[24px] bg-gray-450 rounded group-open:pt-0 group-open:rounded-t-none">
          {spoiler.description &&
            spoiler.description.map((item, index) => (
              <p key={index} className="first:pt-[0] indent-[20px]">
                {item}
              </p>
            ))}
          <div className="mt-[7px]">
            {spoiler.list &&
              spoiler.list.map((item, index) => (
                <div key={index} className={`${item.style}`}>
                  {item.order} <span className="ml-[6px]"> {item.text}</span>
                </div>
              ))}
          </div>
        </div>
      </details>
    </div>
  );
};

export default SpoilerProductionMicroV;
