import React from "react";
import { IIntegrationSolutionsProductItem } from "../pages/IntegrationSolutionsProduct";

export const IntegrationSolutionsProductItem: React.FC<
  IIntegrationSolutionsProductItem
> = ({ title, list, icon }) => {
  return (
    <div className="lg:max-w-512px max-w-full bg-gray-700 md:rounded-3xl rounded-medium pt-[10px] px-[4px] pb-[40px] lg:basis-[47.5%] basis-[100%]  shrink-1 grow-0  mb-[20px] last:mb-[40px]">
      <div
        className={`w-[40px] h-[36px] ${icon} bg-center bg-cover bg-no-repeat self-center mt-[10px] mx-auto`}
      ></div>
      <div className="px-[10px]">
        <div className="text-16-700-advent py-[18px] px-[23px]">{title}</div>
        <ul className="list-none">
          {list.map((item, index) => (
            <li
              className={`text-14-400-inter ${
                list.length !== 1
                  ? "hexagonLiSmall bg-li-small md:bg-li-md"
                  : "pl-[23px]"
              }`}
              key={index}
            >
              {item}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
