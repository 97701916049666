import React from "react";
import { NavHashLink } from "react-router-hash-link";

interface IButtonLinkProps {
  to: string;
  width: string;
  label: string;
  text: string;
  disabled?: boolean;
}

export const ButtonLink: React.FC<IButtonLinkProps> = ({
  to,
  width,
  label,
  text,
  disabled,
}) => {
  return (
    <NavHashLink
      to={to}
      smooth
      className={({ isActive }) =>
        `flex justify-center items-center text-center ${text} 2xl:h-[56px] h-[40px] w-23.6% rounded-xl  hover:bg-gradientBlueDark ${width} ${
          isActive ? "bg-gradientBlueDark" : "bg-gradientBlueLight"
        } ${disabled && "pointer-events-none"}`
      }
    >
      {label}
    </NavHashLink>
  );
};
