import React, { useRef } from "react";
import SpoilerProductionMicroV, {
  ISpoilerMicroVItem,
} from "./SpoilerProductionMicroV";

export interface IProduct {
  title: string;
  description?: string[];
  list?: string[];
  note?: string;
  spoilers: ISpoilerMicroVItem[];
}

interface ISectionMicro5Props {
  section: IProduct;
  buttons: React.ReactNode[];
  bgBig: string;
  bgSmall: string;
  isBig?: boolean;
}

export const SectionMicro5: React.FC<ISectionMicro5Props> = ({
  section,
  buttons,
  bgBig,
  bgSmall,
}) => {
  const microRef = useRef<HTMLDivElement>(null);

  return (
    <div
      ref={microRef}
      className={`${bgBig} 2xl:rounded-3xl rounded-2xl flex flex-col`}
    >
      <div className="h-[76px] md:text-20-600-inter text-16-600-inter tracking-[-1px] 2xl:px-[88px] md:px-[51px] px-[20px] flex items-center justify-between">
        <div>{section.title}</div>
      </div>
      <div
        className={`${bgSmall} 2xl:rounded-3xl rounded-2xl pt-[4px] 2xl:pb-10 pb-9 2xl:pl-[88px] md:pl-[51px] pl-[20px] flex flex-col basis-auto grow`}
      >
        <div className="basis-auto grow 2xl:text-16-500-inter text-14-500-inter placeholder:md:mb-16 mb-[40px]">
          {section.description &&
            section.description.map((item, index) => (
              <div
                key={index}
                className="2xl:text-16-500-inter  text-14-500-inter mt-[25px] first:mt-0"
              >
                {item}
              </div>
            ))}

          {section.spoilers.map((item) => (
            <SpoilerProductionMicroV spoiler={item} key={item.id} />
          ))}
        </div>
        <div className="flex md:justify-end justify-center space-x-5 grow-0 ">
          {buttons}
        </div>
      </div>
    </div>
  );
};
