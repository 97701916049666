import React, { useContext, useState, useCallback, useEffect } from "react";
import { Section } from "../components/Section";
import { ModalContext } from "../context/modal/modalContext";
import { SolutionsWrapper } from "../components/SolutionsWrapper";
import Button from "./../components/Button";
import { InputForm } from "../components/InputForm";
import { Calculator } from "../components/Calculator";
import ButtonDark from "../components/ButtonDark";
import ButtonArrow from "../components/ButtonArrow";

const sectionArendation = {
  title: "Аренда ИТ-инфраструктуры (IaaS)",
  description: [
    "Аренда ИТ-инфраструктуры в облаке по схеме «Инфраструктура как услуга» (Infrastructure as a Service, IaaS) позволяет значительно снизить затраты на создание и предоставление ИТ-сервисов внутренним пользователям",
  ],
  list: [
    "Запуск от 10 минут",
    "Гибкость и повышение КПД",
    "Отсутствие кап. затрат",
    "Снижение опер. затрат",
    "Прозрачное ценообразование",
    "Многоуровневая тех. поддержка",
  ],
  note: "Сервис IaaS построен на базе решений VMware и OpenStack.",
  spoilers: [
    {
      id: 1,
      title: "Виртуальное частное облако",
      description: [
        "Виртуальное частное облако представляет собой полностью виртуализованную ИТ-инфраструктуру предприятия или организации, которая развернута в облаке и предоставляется в аренду по схеме «Инфраструктура как услуга» (Infrastructure as a Service, IaaS).",
        "ИТ-инфраструктура, размещенная в виртуальном частном облаке, позволяет вам получить полностью готовую к эксплуатации информационную среду быстрее, дешевле и надежнее по сравнению с внедрением на собственном оборудовании в публичном IaaS-облаке в дата-центре. Кроме того, заказчик может сам сформировать требуемое виртуальное ИТ-окружение из готовых функциональных модулей. И если потребности вдруг изменятся, то он сможет очень быстро изменить параметры и конфигурацию виртуальной ИТ-инфраструктуры в соответствии с новыми задачами.",
        "По сравнению с существующими публичными облаками наше виртуальное частное облако обладает рядом важных особенностей:",
      ],
      list: [
        "Высокий уровень безопасности",
        "Полный контроль заказчика над прикладным ИТ-окружением и сетями",
        "Возможность использовать высокопроизводительные ИТ-ресурсы",
        "Надежный и быстрый выделенный канал к облаку",
        "Возможность разместить свое оборудование непосредственно внутри сетевого периметра облака",
        "Финансовая ответственность оператора облака в случае нарушения им SLA",
        "Выделенный технический менеджер облака для каждого клиента",
      ],
    },

    {
      id: 2,
      title: "Виртуальный резервный ЦОД (DRaaS)",
      description: [
        "Наша компания предоставляет услугу по обеспечению непрерывного функционирования корпоративной информационной среды и гарантированному восстановлению работоспособности информационных систем заказчика, в том числе облачных, в случае сбоев, аварий и выхода из строя критически важных и наиболее уязвимых элементов ИТ-инфраструктуры. Для этого в облаке разворачивается резервный виртуальный ЦОД, который предоставляется заказчику в аренду в соответствии с концепцией «Восстановление как услуга» (Disaster Recovery as a Service, DRaaS).",
        "Системное ядро виртуального резервного дата-центра совместимо с платформами Windows и Linux и поддерживает работу физических и виртуальных серверов независимо от используемого типа виртуализации. Это позволяет в короткие сроки восстанавливать работоспособность ИТ-систем и баз данных, размещенных на физическом ИТ-оборудовании или в виртуальных средах, а также оперативно проводить миграцию и консолидацию разнородных систем.",
        "Виртуальный резервный ЦОД в облаке:",
      ],
      list: [
        "Значительная экономия (до 100 раз) по сравнению с объемом капитальных затрат на строительство физического резервного дата-центра",
        "Значительно более быстрое внедрение в эксплуатацию по сравнению со строительством с нуля и запуском резервной серверной комнаты или ЦОД",
        "Восстановление работоспособности ИТ-систем со всеми настройками от 15 минут",
        "Восстановление работоспособности баз данных от 1 минуты",
        "Копирование всех изменений в основной ИТ-инфраструктуре в режиме реального времени",
        "Совместимость с большим ассортиментом серверного оборудования, СХД и технологий виртуализации",
        "Низкая нагрузка на каналы связи и минимальное потребление ресурсов облака",
      ],
    },
    {
      id: 3,
      title: "Облачная серверная платформа (PaaS)",
      description: [
        "Мы предоставляем услуги доступа к вычислительным мощностям виртуальных серверов дата-центра по модели «Платформа как услуга» (Platform as a Service, PaaS).",
        "С помощью этого сервиса можно в короткий срок запустить необходимое количество виртуальных машин требуемой производительности и в любой конфигурации из облака, а также быстро и экономически эффективно реализовывать проекты со слабо прогнозируемой нагрузкой воспользовавшись всеми преимуществами платформы.",
        "Кроме того, сервис может быть использован под временные задачи, такие как разработка и тестирование ИТ-решений.",
      ],
    },
    {
      id: 4,
      title: "Виртуальные рабочие места (DaaS)",
      description: [
        "Высокие издержки промышленной эксплуатации персональных компьютеров в крупных и территориально распределенных организациях все чаще вынуждают организации искать альтернативу использованию ПК для оснащения рабочих мест сотрудников.",
        "Для решения этой задачи наша компания предлагает использовать сервис по организации рабочих мест в виде виртуальных рабочих столов в соответствии с концепцией «Рабочее место как услуга» (Desktop as a Service, DaaS). Приобретение сервиса DaaS позволяет обеспечить своих сотрудников полностью готовым и стандартизованным рабочим окружением, значительно снизить затраты на содержание и обслуживание рабочих мест пользователей, а также повысить безопасность и продуктивность сотрудников.",
        "В этом случае в защищенном облаке формируется необходимая инфраструктура по технологии виртуальных рабочих столов (Virtual Desktop Infrastructure, VDI) со всеми нужными приложениями и системным ПО, и пользователи получают удаленный защищенный доступ к ним с любых устройств (тонкие клиенты, ПК, планшеты, смартфоны) и из любого места, будь то офис, дом или гостиница в другом городе. При этом после подключения они могут использовать все сетевые и локальные устройства, включая различные накопители и периферийное оборудование.",
        "Работа с приложениями осуществляется за счет серверных ресурсов в облаке, а обработка и хранение данных осуществляются в защищенном корпоративном хранилище. Новые добавленные приложения в облаке автоматически отображаются в списке доступных пользователю приложений.",
      ],
    },
  ],
};
const sectionDevelop = {
  title: "Разработка автоматизированных инженерных систем",
  description: [
    "Мы предлагаем полный цикл разработки и введения в эксплуотацию современных автоматизированных систем:",
  ],
  list: [
    "Прототипирование электронных устройств (составление принципиальных электрических схем, проектирование механических узлов и корпусов устройств, 3D-моделирование, 3D-печать)",
    "Реализация ПО для электронных устройств и embedded-систем",
    "Анализ и реинжиниринг решений представленных на рынке",
    "Разработка электротехнических промышленных машин и производственных систем (проектирование, подготовка документации, пусконаладка)",
  ],
  note: "Как мы работаем:",
  listNext: [
    "Проводим агрегацию требований (подготавливаем user story)",
    "Готовим техническое задание (наполняем и согласовываем backlog и карту проекта)",
    "Разрабатываем прототип, проводим тестирование и испытания",
    "Оформляем необходимую документацию",
    "Передаем устройство и интеллектуальную собственность по проекту",
    "Обеспечиваем необходимую техническую поддержку",
  ],
  spoilers: [],
};

export function HardwareSolutions() {
  const { show } = useContext(ModalContext);

  const [firstButton, setFirstButton] = useState<boolean>(true);
  const [secondButton, setSecondButton] = useState<boolean>(false);
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  const handleWindowResize = useCallback(() => {
    setWindowSize(window.innerWidth);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [handleWindowResize]);

  useEffect(() => {
    if (windowSize > 768) {
      setFirstButton(true);
      setSecondButton(true);
    } else {
      setFirstButton(true);
      setSecondButton(false);
    }
  }, [windowSize]);

  const buttonHandler = () => {
    setFirstButton(!firstButton);
    setSecondButton(!secondButton);
  };

  return (
    <div>
      <div className="md:hidden fixed right-[20px] top-[394px] transition-all z-50">
        <ButtonArrow
          style="bg-mailGradient md:w-[56px] md:h-[65px] w-[44px] h-[52px]"
          onClick={() =>
            show(
              <InputForm
                titleSection={`${
                  firstButton
                    ? "Аренда ИТ-инфраструктуры (IaaS)"
                    : "Разработка автоматизированных инженерных систем"
                }`}
              />,
              "",
              "md:modalInputForm modalInputFormMD"
            )
          }
        />
        <ButtonArrow
          style="bg-upSmallGradient md:w-[56px] md:h-[65px] w-[44px] h-[52px]"
          onClick={() =>
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            })
          }
        />
      </div>
      <div className="md:hidden px-[20px] space-y-5">
        <div className="text-24-600-inter">Аппаратные решения</div>
        <ButtonDark
          label="Аренда ИТ-инфраструктуры (IaaS)"
          isActive={firstButton}
          onClick={buttonHandler}
        />
        <ButtonDark
          label="Разработка автоматизированных 
инженерных систем"
          isActive={secondButton}
          onClick={buttonHandler}
        />
      </div>
      <SolutionsWrapper
        sectionFirst={
          <Section
            section={sectionArendation}
            buttons={[
              <Button
                key="productArendationCalculator"
                label="Калькулятор"
                style="w-[157px] 2xl:text-16-600-inter text-14-600-inter"
                onClick={() =>
                  show(
                    <Calculator />,
                    "Калькулятор",
                    "rounded-2xl bg-gray-300 border-solid border border-gray-800 py-10 px-5"
                  )
                }
              />,
              <Button
                key="productArendationApplication"
                label="Оставить заявку"
                style="w-[157px] 2xl:text-16-600-inter text-14-600-inter"
                onClick={() =>
                  show(
                    <InputForm titleSection={sectionArendation.title} />,
                    "",
                    "md:modalInputForm modalInputFormMD"
                  )
                }
              />,
            ]}
            bgBig="bg-gray-300 bg-bgBlock1 bg-no-repeat bg-right-top h-full"
            bgSmall="md:bgArendation bgArendationMD 2xl:pr-[86px] md:pr-[51px] pr-[20px]"
            isActive={firstButton}
            isCanOpen={true}
          />
        }
        sectionSecond={
          <Section
            section={sectionDevelop}
            buttons={[
              <Button
                key="productArendation2Application"
                label="Оставить заявку"
                style="w-[157px] 2xl:text-16-600-inter text-14-600-inter"
                onClick={() =>
                  show(
                    <InputForm titleSection={sectionDevelop.title} />,
                    "",
                    "md:modalInputForm modalInputFormMD"
                  )
                }
              />,
            ]}
            bgBig="bg-gray-300 h-full"
            bgSmall="bg-gray-700 bg-bgBlock2 bg-no-repeat bg-left-bottom 2xl:pr-[86px] md:pr-[51px] pr-[20px]"
            isActive={secondButton}
            isCanOpen={true}
          />
        }
      />
    </div>
  );
}
