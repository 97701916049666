import React, { useContext } from "react";
import { ModalContext } from "../context/modal/modalContext";
import Button from "./Button";

function ApplicationAccepted() {
  const { hide } = useContext(ModalContext);
  return (
    <div>
      <div className="mt-2 mx-auto h-[72px] w-[72px] rounded-full bg-aquamarine bg-check bg-no-repeat bg-center"></div>

      <h5 className="my-[26px] text-32-700-advent text-center">
        Заявка принята
      </h5>

      <Button
        style="w-312 2xl:text-16-600-inter text-14-600-inter"
        onClick={hide}
        label="OK"
      />
    </div>
  );
}

export default ApplicationAccepted;
