import React from "react";
import { SoftwareSolutionsProductItem } from "../components/SoftwareSolutionsProductItem";
import WriteUs from "../components/WriteUs";

const softwareProducts = [
  {
    id: "1",
    image: "bg-earthSmall",
    title: "Геофизика",
    description:
      "Программное обеспечение для моделирования, обработки, интерпретации и визуализации геофизических данных",
  },
  //   {
  //     id: "2",
  //     image: "",
  //     title: "Био",
  //     description: "Раздел находится в стадии наполнения контентом",
  //   },
  //   {
  //     id: "3",
  //     image: "",
  //     title: "Финтех",
  //     description: "Раздел находится в стадии наполнения контентом",
  //   },
];

export function SoftwareSolutionsProduct() {
  return (
    <div>
      <div className="flex flex-wrap">
        {softwareProducts.map((item) => (
          <SoftwareSolutionsProductItem
            key={item.id}
            image={item.image}
            title={item.title}
            description={item.description}
          />
        ))}
        <WriteUs titleSection="Програмные решения. Продукция" />
      </div>
    </div>
  );
}
