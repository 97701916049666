import React from "react";
import { RoutesName } from "./AppRouter";
import { ButtonLinkMobile } from "./ButtonLinkMobile";

interface INavbarMobile {
  menuClose?: () => void;
}

export function NavbarMobile({ menuClose }: INavbarMobile) {
  return (
    <div className="bg-gray-800 grow-0 w-full pt-[10px] px-[48px] pb-[90px]">
      <ButtonLinkMobile
        to={RoutesName.HARDWARE_SOLUTIONS}
        width="w-full mt-[20px]"
        label="Аппаратные решения"
        text="text-16-600-inter tracking-[-0.05em]"
        onClick={menuClose}
      />
      <ButtonLinkMobile
        to={RoutesName.INTEGRATION_SOLUTIONS}
        width="w-full mt-[20px]"
        label="Интеграционные решения"
        text="text-16-600-inter tracking-[-0.05em]"
        onClick={menuClose}
      />
      <ButtonLinkMobile
        to={RoutesName.SOWTWARE_SOLUTIONS}
        width="w-full mt-[20px]"
        label="Программные решения"
        text="text-16-600-inter tracking-[-0.05em]"
        onClick={menuClose}
      />
      <ButtonLinkMobile
        to={RoutesName.OUR_PROJECTS}
        width="w-full mt-[20px]"
        label="Наши проекты"
        text="text-16-600-inter tracking-[-0.05em]"
        onClick={menuClose}
      />
    </div>
  );
}
