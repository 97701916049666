import React from "react";

interface IButtonArrowProps {
  style: string;
  onClick: () => void;
}

const ButtonArrow: React.FC<IButtonArrowProps> = ({ style, onClick }) => {
  return (
    <button
      className={`${style}  block rounded-medium transition-all cursor-pointer`}
      onClick={onClick}
    ></button>
  );
};

export default ButtonArrow;
